<template>
  <section style="display: inline-block;">
    <el-button type="success" size="small" @click="handleDialogShow">短链生成</el-button>
    <el-dialog title="短链生成" :visible.sync="dialogVisible" @close="handleDialogClose" width="60%" append-to-body>
      <el-form :model="form" label-position="right" label-width="120px" :rules="rules" ref="form">
        <el-form-item label="短链前缀" prop="prefix">
          <el-select v-model="form.prefix" filterable placeholder="请选择 前缀-入口域名" style="width: 100%;" clearable>
            <el-option value="">无/空白前缀</el-option>
            <el-option v-for="item in prefixData" :key="item.prefix" :label="item.prefix + ' - ' + item.host" :value="item.prefix"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="目标网址" prop="longUrl">
          <el-input v-model.trim="form.longUrl" placeholder="请输入目标网址"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="handleDialogSubmit">提 交</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import api from '@/api'

export default {
  name: 'UrlGenerate',
  props: {},
  data () {
    return {
      dialogVisible: false,
      form: {
        prefix: '',
        longUrl: ''
      },
      rules: {
        longUrl: [{ type: 'url', required: true, message: '请输入长链接', trigger: 'blur' }]
      },
      prefixData: []
    }
  },
  created () {
    this.fetchPrefixData()
  },
  methods: {
    fetchPrefixData () {
      api.get('/short-url/admin/url/prefixes').then(res => {
        this.prefixData = res
      })
    },
    handleDialogShow () {
      this.dialogVisible = true
    },
    handleDialogClose () {
      this.dialogVisible = false
      this.$refs.form.clearValidate()
    },
    handleDialogSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          api.post('/short-url/admin/url/generate', Object.assign({}, this.form)).then(res => {
            const { code, msg } = res
            if (code === 200) {
              const data = res.data
              this.dialogVisible = false
              console.log(`[short-url] generate: ${data.prefixShortUrl} ==> ${data.longUrl}`)
              this.$notify({
                title: '短链生成',
                message: `${data.prefixShortUrl}\n${data.longUrl}`,
                type: 'success',
                duration: 0
              })
              this.$emit('complete', res.data)
            } else {
              this.$message.error(`操作失败: [${code}] ${msg}`)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
