import options from '../common/options'
/**
 * 日期格式化
 * @param time 接收一个可被 new Date()识别的时间格式，未识别直接返回原数据
 * @return {string} 返回一个字符串 YYYY-MM-DD HH:mm:ss
 */
const formatTime = time => {
  if (!time) {
    return ''
  }
  const nTime = new Date(time)
  // 日期对象返回Invalid Date 将原数据返回
  if (!(nTime instanceof Date && !isNaN(nTime.getTime()))) {
    return time
  }
  const y = nTime
    .toLocaleDateString() // "2020/12/23" 输出是酱紫的
    .split('/')
    .map(item => item.padStart(2, '0'))
    .join('-')
  const t = nTime
    .toTimeString() // "11:51:43 GMT+0800 (中国标准时间)" 输出是酱紫的
    .split(' ')[0]
    .split(':')
    .map(item => item.padStart(2, '0'))
    .join(':')
  return y + ' ' + t
}

const optionsFormat = (key, options) => {
  if (key === '' || !options) {
    return key
  }
  for (const item of options) {
    if (item.value * 1 === key * 1) {
      return item.value + '-' + item.label
    }
  }
  return key
}

const f = {
  productsToStr (products) {
    const arr = []
    for (const item of JSON.parse(products)) {
      arr.push(Number(item.price))
    }
    return arr
  },
  festivalsToStr (festivals) {
    const arr = []
    for (const item of JSON.parse(festivals)) {
      arr.push(item)
    }
    return arr
  },
  workingsToStr (workings) {
    const arr = []
    for (const item of JSON.parse(workings)) {
      arr.push(item)
    }
    return arr
  },
  resize () {
    return document.body.clientWidth
  },
  formatTime,
  channelStatusFormat (val) {
    return optionsFormat(val, options.statusOption)
  },
  productStatusFormat (val) {
    return optionsFormat(val, options.productStatusOption)
  },
  optionsFormat,
  packageTypesFormat (val) {
    return optionsFormat(val, options.packageTypes)
  },
  rateFormat (val) {
    return optionsFormat(val, options.rateOption)
  },
  xsTypeFormat (val) {
    return optionsFormat(val, options.xsTypeOption)
  },
  autoFormat (prop, val) {
    if (prop === 'status') {
      return this.channelStatusFormat(val)
    }
    if (prop === 'packageType') {
      return this.packageTypesFormat(val)
    }
    if (prop === 'festivalsToStr') {
      return this.festivalsToStr(val)
    }
    if (prop === 'workingsToStr') {
      return this.workingsToStr(val)
    }
    if (prop === 'createdAt' || prop === 'updatedAt') {
      return this.formatTime(val)
    }
    return val
  }
}

export default f
