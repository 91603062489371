<template>
  <section>
<!--    <div class="box">-->
<!--      <h3 style='text-align: left;margin: 0'>节点同步</h3>-->
<!--    </div>-->
    <el-row :gutter="10" style="margin-top: 10px">
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <!-- 节点列表 -->
        <el-tabs v-model="tabNodeGroup" type="border-card" @tab-click="handleTabNodeGroupClick" style="padding: 0;margin: 0 0 10px">
          <el-checkbox :indeterminate='isIndeterminate' v-model='checkAll' @change='handleCheckAllChange'>全选</el-checkbox>
          <el-tab-pane :label="item.groupKey" :name="item.groupKey" v-for="(item, i) in groupNodes" :key="i">
            <el-row>
              <el-col :span="18">
                <el-checkbox-group v-model='nodeList' @change='handleCheckedCitiesChange' style="display: flex;flex-direction: column;justify-content: space-between">
                  <el-checkbox v-for='item in nodeOption' :label='item' :key='item' style="margin-top: 5px"></el-checkbox>
                </el-checkbox-group>
              </el-col>
              <el-col :span="6">
                <div v-for='item in nodeOption' :key='item' style="margin-top: 10px;">
                  <span v-if="visible" :class="handleHint(item) ? 'success' : 'error'" style="display: flex;flex-direction: column;margin-top: 5px">{{handleHint(item) ? '成功' : '失败'}}</span>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <!-- 操作功能 -->
        <el-tabs v-model="activeName" type="border-card" style="margin-top: 10px">
          <!-- 渠道参数 -->
          <el-tab-pane label="渠道参数" name="channel">
            <div style="margin-bottom: 20px">
              <el-select v-model="channelId" collapse-tags style="width: 100%" placeholder="--请选择渠道--" @change="handleChannelChange" clearable filterable>
                <el-option v-for="item in channelOptions" :key="item.id" :label="item.id + '--' + item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
            <el-card style="margin-top: 10px">
              <el-button type="primary" @click='handleChannelNode("post")' :disabled="!Boolean(channelId)" size="small">添加</el-button>
              <el-button type="primary" @click='handleChannelNode("put")' :disabled="!Boolean(channelId)" size="small">修改</el-button>
              <el-button type="danger" @click='handleChannelNode("delete")' :disabled="!Boolean(channelId)" size="small">删除</el-button>
              <el-button type="success" @click='handleGetChannelArgs' :disabled="!Boolean(channelId)" size="small">查看</el-button>
              <!--          <el-button type="danger" >重载</el-button>-->
            </el-card>
          </el-tab-pane>
          <!-- 产品参数 -->
          <el-tab-pane label="产品参数" name="app">
            <el-button type="primary" @click='handleGetGameNode' size="small">查看</el-button>
            <el-button type="danger" @click='handleChangeGameNode' size="small">修改</el-button>
          </el-tab-pane>
          <!-- 节假日 -->
          <el-tab-pane label="节假日" name="holiday">
            <div style="margin-bottom: 20px">
              <el-select v-model="holidayYear" placeholder="--请选择年份--" @change="handleHolidayYearChange" style="width: 100%" clearable filterable>
                <el-option v-for="item in holidayOptions" :key="item.id" :value="item.year" :label="item.year"></el-option>
              </el-select>
            </div>
            <el-card style="margin-top: 10px">
              <el-button type="danger" @click="handleNodeHolidayOperate('delete')" :disabled="!Boolean(holidayYear)" size="small">删除</el-button>
              <el-button type="warning" @click="handleNodeHolidayOperate('put')" :disabled="!Boolean(holidayYear)" size="small">修改</el-button>
              <el-button type="success" @click="handleNodeHolidayOperate('get')" :disabled="!Boolean(holidayYear)" size="small">查看</el-button>
            </el-card>
          </el-tab-pane>
          <!-- 渠道包 -->
          <el-tab-pane label="渠道包" name="pkgConfig">
            <div style="margin-bottom: 20px">
              <el-select v-model="pkgValue" collapse-tags style="width: 100%" placeholder="--请选择证书包名--" @change="handleChannelChange" clearable filterable>
                <el-option v-for="item in pkgOptions" :key="item.id" :label="item.id + '--' + item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
            <el-card style="margin-top: 10px">
              <el-button type="primary" @click='handlePkgConfig("post")' :disabled="!Boolean(pkgValue)" size="small">添加</el-button>
              <el-button type="primary" @click='handlePkgConfig("put")' :disabled="!Boolean(pkgValue)" size="small">修改</el-button>
              <el-button type="danger" @click='handlePkgConfig("delete")' :disabled="!Boolean(pkgValue)" size="small">删除</el-button>
              <el-button type="success" @click='handlePkgConfig("get")' :disabled="!Boolean(pkgValue)" size="small">查看</el-button>
            </el-card>
          </el-tab-pane>
          <!-- 产品参数 -->
          <el-tab-pane label="Visitor" name="visitor">
            <el-button type="primary" @click='handleVisitorPull' size="small">更新</el-button>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <el-card v-if="visible">
          <el-row :gutter="15" style="display: flex;flex-direction: column">
            <el-col v-for='(item, i) in result' :key="i">
              <h4>{{item.host}}</h4>
              <pre :class="item.status? 'suc' : 'err'">{{ item.value }}</pre>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import api from '@/api/channel'

export default {
  name: 'index',
  created () {
    this.fetchData()
    this.fetchHolidays()
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    }
  },
  watch: {
    appId (val, oldVal) {
      if (val === oldVal) {
        return
      }
      this.fetchData()
    }
  },
  data () {
    return {
      tableData: [],
      result: [],
      argument: '',
      channelOptions: [],
      channelId: '',
      tabNodeGroup: '',
      groupNodes: [],
      nodeOption: [],
      nodeList: [],
      checkAll: true, // 全选
      isIndeterminate: false,
      visible: false,
      activeName: 'channel',
      holidayOptions: [],
      holidayYear: '',
      ufwType: '1',
      pkgValue: '',
      pkgOptions: []
    }
  },
  mounted () {
    api.getPkgList({ size: 1000 }).then(res => {
      this.pkgOptions = res._embedded.pkgConfigs.reduce((pre, cur) => {
        const arr = {
          id: cur.pkgName,
          name: cur.name
        }
        return pre ? [...pre, arr] : [arr]
      }, [])
    })
  },
  methods: {
    fetchData () {
      this.groupNodes = []
      this.clearNodeResult()
      api.getNodeList({ appId: this.appId, format: 'group' }).then(res => {
        if (res.length <= 0) {
          return
        }
        this.groupNodes = res
        const gn = res.find(d => d.selected) || res[0]
        this.tabNodeGroup = gn.groupKey
        this.nodeOption = gn.nodes
        this.handleCheckAllChange(true)
      })
      this.channelOptions = []
      api.getChannelData(this.appId).then(res => {
        this.channelOptions = Object.assign([], res)
      })
    },
    // 节点列表页签切换
    handleTabNodeGroupClick () {
      this.clearNodeResult()
      const gn = this.groupNodes.find(d => d.groupKey === this.tabNodeGroup) || this.groupNodes[0]
      this.nodeOption = gn.nodes
      this.handleCheckAllChange(true)
    },
    // 全选
    handleCheckAllChange (val) {
      this.nodeList = val ? this.nodeOption : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange (value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.nodeOption.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.nodeOption.length
      this.visible = false
    },
    // 渠道增删改
    handleChannelNode (type) {
      this.result = []
      if (!this.nodeList.length) {
        return this.$message.info('请选择节点')
      }
      if (type !== 'delete') {
        this.handleFetchData(type)
      } else {
        this.$confirm('此操作将全部删除, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.handleFetchData(type)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      }
    },
    // 增删改请求
    handleFetchData (type) {
      for (const item of this.nodeList) {
        api.handleArgsByType(this.channelId, type, { host: item }).then(res => {
          if (res.code === 200) {
            if (type === 'delete') {
              this.$message.success('成功')
              this.visible = false
            }
            this.result.push({ host: item, value: res.msg, status: true })
          } else {
            this.result.push({ host: item, value: res.msg, status: false })
            this.visible = true
          }
        })
      }
    },
    // 查看渠道节点
    handleGetChannelArgs () {
      if (!this.nodeList.length) {
        return this.$message.info('请选择节点')
      }
      this.result = []
      for (const item of this.nodeList) {
        api.getChannelArgs(this.channelId, { host: item }).then(res => {
          this.result.push({ host: item, value: res, status: true })
          this.visible = true
        })
      }
    },
    // 查看游戏节点
    handleGetGameNode () {
      if (!this.nodeList.length) {
        return this.$message.info('请选择节点')
      }
      this.result = []
      for (const item of this.nodeList) {
        api.getAppArgs(this.appId, { host: item }).then(res => {
          this.result.push({ host: item, value: res, status: true })
          this.visible = true
        })
      }
    },
    // 修改游戏节点
    handleChangeGameNode () {
      if (!this.nodeList.length) {
        return this.$message.info('请选择节点')
      }
      this.result = []
      for (const item of this.nodeList) {
        api.changeAppArgs(this.appId, { host: item }).then(res => {
          if (res.code === 200) {
            this.result.push({ host: item, value: res.msg, status: true })
            this.visible = false
          } else {
            this.result.push({ host: item, value: res.msg, status: false })
            this.visible = true
          }
        })
      }
    },
    handleHint (node) {
      if (!this.result.length) {
        return false
      }
      for (const item of this.result) {
        if (item.host === node && !item.status) {
          return false
        }
      }
      return true
    },
    handleChannelChange () {
      this.clearNodeResult()
    },
    clearNodeResult () {
      this.result = []
      this.nodeList = []
      this.isIndeterminate = false
      for (const item of this.nodeOption) {
        this.nodeList.push(item)
      }
      this.checkAll = true
      this.visible = false
    },
    clearResult () {
      this.result = []
      this.visible = false
    },
    fetchHolidays () {
      api.getHolidays().then(res => {
        this.holidayOptions = Object.assign([], res)
      })
    },
    handleHolidayYearChange () {
      this.clearNodeResult()
    },
    handleNodeHolidayOperate (type) {
      this.clearResult()
      // for (const host of ['192.168.0.11:10160', ...this.nodeList]) {
      for (const host of this.nodeList) {
        api.handleNodeHoliday(type, this.holidayYear, { host: host }).then(res => {
          if (res.code === 200) {
            this.result.push({ host: host, value: res, status: true })
            this.visible = true
          } else {
            this.result.push({ host: host, value: res.msg, status: false })
            this.visible = true
          }
        })
      }
    },
    handleUfwChange () {
      this.clearNodeResult()
    },
    handleUfwRules () {
      this.clearNodeResult()
      if (!this.ufwType) {
        this.$message.info('请选择UFW类型')
        return
      }
      for (const host of this.nodeList) {
        const apiFunc = this.ufwType === '1' ? api.ufwBlacklists : api.ufwWhitelists
        apiFunc({ host: host }).then(res => {
          if (res.code === 200) {
            this.result.push({ host: host, value: res.data || [], status: true })
            this.visible = true
          } else {
            this.result.push({ host: host, value: res.msg, status: false })
            this.visible = true
          }
        })
      }
    },
    handleUfwReload () {
      this.clearNodeResult()
      for (const host of this.nodeList) {
        api.ufwReload({ host: host }).then(res => {
          if (res.code === 200) {
            this.result.push({ host: host, value: res.msg, status: true })
            this.visible = true
          } else {
            this.result.push({ host: host, value: res.msg, status: false })
            this.visible = true
          }
        })
      }
    },
    // pkg配置
    handlePkgConfig (type) {
      if (!this.nodeList.length) {
        return this.$message.error('请选择节点')
      }
      switch (type) {
        case 'delete':
          this.$confirm('确定是否删除?', '', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.handlePkgFetchData(type)
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
          break
        default:
          this.handlePkgFetchData(type)
          break
      }
    },
    handlePkgFetchData (type) {
      if (this.result.length) this.result = []
      for (const item of this.nodeList) {
        api.handlePkgRequest(this.pkgValue, type, { host: item }).then(res => {
          this.visible = true
          if (res.code === 200) {
            if (type === 'delete') {
              this.$message.success('成功')
              this.visible = false
            }
            this.result.push({ host: item, value: res.data, status: true })
          } else {
            this.result.push({ host: item, value: res.msg, status: false })
            this.visible = true
          }
        })
      }
    },
    handleVisitorPull () {
      if (!this.nodeList.length) {
        return this.$message.info('请选择节点')
      }
      this.result = []
      for (const item of this.nodeList) {
        api.visitorPull({ host: item }).then(res => {
          this.result.push({ host: item, value: res, status: true })
          this.visible = true
        })
      }
    }
  }
}
</script>

<style scoped>
  .success {
    color: #72C22D;
  }
  .error {
    color: #EC6B6A;
  }
  .suc {
    overflow-x: auto;
  }
  .err {
    color: #EC6B6A;
  }
</style>
