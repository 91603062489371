<template>
  <rest
    :remote="remote"
    :table-columns="tableColumns"
    :formInitialize="formInitialize"
    :form-rules="formRules"
    :exportable="true"
    :create-able="true" :update-able="true" :delete-able="true"
  >
  </rest>
</template>

<script>
import Rest from '../../components/Spring/Rest.vue'
export default {
  name: 'extConfigTemplates',
  components: { Rest },

  data () {
    return {
      remote: {
        path: 'channel',
        resource: this.$options.name,
        realtime: true
      },
      formInitialize: {
        args: [],
        timeout: 3000
      },
      formRules: {
        dataKey: [{ required: true, message: '请填写 key' }],
        dataValue: [{ required: true, message: '请填写 value' }],
        name: [{ required: true, message: '请填写 name' }],
        dataDesc: [{ required: false, message: '请填写 desc' }]
      }
    }
  },
  computed: {
    tableColumns () {
      return [
        // { label: 'ID', prop: 'id', hiddenForm: true },
        { label: 'Key', prop: 'dataKey' },
        { label: 'Value', prop: 'dataValue' },
        { label: 'Name', prop: 'name' },
        { label: 'Desc', prop: 'dataDesc', type: 'textarea' }
      ]
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
