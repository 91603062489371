<template>
  <rest
    :remote="remote"
    :table-columns="tableColumns"
    :form-initialize="formInitialize"
    :form-rules="formRules"
    :exportable="true"
    :create-able="true" :update-able="true" :delete-able="true"
    :listeners="listeners"
  >
    <template slot="tableColumn-dataKey" slot-scope="{row}">
      <el-popover placement="top-start" trigger="hover">
        <el-alert type="warning" :description="templateDescFormatter(row)" :closable="false"></el-alert>
        <el-tag slot="reference" type="warning" size="small">{{ templateNameFormatter(row) }}</el-tag>
      </el-popover>
    </template>
    <template slot="formItem-dataKey" slot-scope="{form, item}">
      <el-select v-model="form[item.prop]" placeholder="请选择" filterable clearable style="width: 100%" @change="handleDataKeyChange(form, item)">
        <el-option v-for="t in templateMap" :key="t[0]" :label="t[1].dataKey + ' - ' + t[1].name" :value="t[1].dataKey"></el-option>
      </el-select>
      <el-alert type="warning" :title="form.templateName" :description="form.templateDesc" :closable="false" style="margin-top: 10px;"></el-alert>
    </template>
  </rest>
</template>

<script>
import Rest from '../../components/Spring/Rest.vue'
import api from '@/api/channel'
export default {
  name: 'extChannelConfigs',
  components: { Rest },

  data () {
    return {
      remote: {
        path: 'channel',
        resource: this.$options.name,
        realtime: false,
        reloadUrl: '/channel/admin/cache/reload/ext/config/channel'
      },
      formRules: {
        appId: [{ required: true, message: '请填写 appId' }],
        channelId: [{ required: true, message: '请填写 channelId' }],
        dataKey: [{ required: true, message: '请填写 key' }],
        dataValue: [{ required: true, message: '请填写 value' }]
      },
      listeners: {
        handleFetchData: this.handleFetchData,
        beforeFormEdit: this.handleBeforeFormEdit
      },
      templateMap: new Map()
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    appOptions () {
      return this.$store.getters.appOptions
    },
    formInitialize () {
      return {
        appId: this.appId,
        dataKey: '',
        dataValue: ''
      }
    },
    channelOptions () {
      return this.$store.getters.channelOptions
    },
    tableColumns () {
      return [
        // { label: 'ID', prop: 'id', hiddenForm: true },
        { label: '游戏', prop: 'appId', type: 'select', options: this.appOptions, disabled: true },
        { label: '渠道', prop: 'channelId', type: 'select', options: this.channelOptions, disabled: true },
        { label: 'Key', prop: 'dataKey', formType: 'slot' },
        { label: 'Value', prop: 'dataValue' },
        { label: 'Ext模版', prop: 'dataKey', hiddenForm: true, type: 'slot' }
      ]
    }
  },
  mounted () {
    this.fetchExtConfigTemplates()
  },
  methods: {
    handleFetchData (dataList) {
      if (!dataList) {
        return []
      }
      for (const item of dataList) {
        const t = this.getTemplate(item.dataKey)
        item.templateName = t.name
        item.templateDesc = t.dataDesc
      }
      return dataList
    },
    fetchExtConfigTemplates () {
      api.getExtConfigTemplates().then(res => {
        const map = new Map()
        for (const t of res) {
          map.set(t.dataKey, t)
        }
        this.templateMap = map
      })
    },
    getTemplate (dataKey) {
      return this.templateMap.get(dataKey) || {}
    },
    changeFormTemplateData (form) {
      const t = this.getTemplate(form.dataKey)
      form.templateName = t.name
      form.templateDesc = t.dataDesc
      // 新增表单或无值时，使用模版值填充
      if (form.tableDataIndex < 0 || !form.dataValue) {
        form.dataValue = t.dataValue
      }
    },
    handleDataKeyChange (form, data) {
      this.changeFormTemplateData(form)
    },
    handleBeforeFormEdit (form) {
      this.changeFormTemplateData(form)
    },
    templateNameFormatter (row) {
      const t = this.getTemplate(row.dataKey)
      return t.name
    },
    templateDescFormatter (row) {
      const t = this.getTemplate(row.dataKey)
      return t.dataDesc || '--'
    }
  }
}
</script>

<style scoped>
</style>
