<template>
  <section>
    <div class="box">
<!--      <h3 style='text-align: left;margin: 0'>广告应用</h3>-->
      <div style="display: flex;justify-content: space-between;">
        <el-button type='primary' @click='handleAddPoses'>新增</el-button>
        <el-input v-model="search" placeholder="输入关键字搜索" style="width: 180px"></el-input>
      </div>
    </div>
    <div class="box">
      <el-table :data='tables' stripe style='width: 100%' :header-cell-style='setTableHeader'>
        <el-table-column prop='id' label='ID' width='80px'> </el-table-column>
        <el-table-column prop='name' label='应用名称' width=' 110px'>
          <template slot-scope='scope'>
            <el-button type='text' @click='handleUpdatePoses(scope.$index, scope.row)'>{{scope.row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop='appKey' label='AppKey'>
          <template slot-scope='scope'>
            <el-popover placement='bottom' trigger='click' :content='scope.row.appKey'>
              <el-button slot='reference' size="mini">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop='appSecret' label='AppSecret'>
          <template slot-scope='scope'>
            <el-popover placement='bottom' trigger='click' :content='scope.row.appSecret'>
              <el-button slot='reference' size="mini">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop='notifyUrl' label='通知地址'>
          <template slot-scope='scope'>
            <el-popover placement='bottom' trigger='click' :content='scope.row.notifyUrl'>
              <el-button slot='reference' size="mini">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop='poses' label='广告位'>
          <template slot-scope='scope'>
            <el-button type='text' size='small' @click='handleUpdatePoses(scope.$index, scope.row)'>特殊配置</el-button>
          </template>
        </el-table-column>
        <el-table-column label='操作' fixed='right' align='right'>
          <template slot-scope='scope'>
            <el-button type='info' size='mini' circle @click='handleDeletePoses(scope.row.id, scope.$index)' icon='el-icon-delete'></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <poses-dialog :posesData="posesData" :dialogVisible="dialogVisible" @handleCloseDialog="dialogVisible=false" @handleSaveDialog="handlePosesDialogChange"></poses-dialog>
  </section>
</template>

<script>
import PosesDialog from './PosesDialog'
import api from '@/api/channel'
// import f from '@/filter'

export default {
  name: 'index',
  components: { PosesDialog },
  created () {
    this.fetchData()
  },
  data () {
    return {
      tableData: [],
      search: '',
      dialogVisible: false,
      posesData: {}
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    tables () {
      const search = this.search
      if (search) {
        return this.tableData.filter(data => {
          return Object.keys(data).some(key => {
            return String(data[key]).toLowerCase().indexOf(search) > -1
          })
        })
      }
      return this.tableData
    }
  },
  watch: {
    appId (val, oldVal) {
      if (val === oldVal) {
        return
      }
      this.fetchData()
    }
  },
  methods: {
    fetchData () {
      api.getAdnetGames().then(res => {
        this.tableData = res
      })
    },
    // 修改产品
    handleUpdatePoses (index, rowData) {
      const data = Object.assign({}, rowData)
      data.poses = JSON.parse(data.poses)
      this.posesData = data
      this.dialogVisible = true
    },
    // 新增产品
    handleAddPoses () {
      const id = this.tableData.length ? this.tableData[this.tableData.length - 1].id : 0
      this.posesData = {
        id: id + 1,
        poses: [{ posId: '', posName: '', rewardName: '', rewardAmount: '', pangolinPosId: '', pangolinSecret: '' }],
        appKey: '',
        appSecret: ''
      }
      this.dialogVisible = true
    },
    // 接收子组件
    handlePosesDialogChange (value) {
      this.dialogVisible = value
      this.fetchData()
    },
    // 删除产品
    handleDeletePoses (id, i) {
      this.$confirm('确定删除吗?', '提示', { type: 'warning' }).then(() => {
        api.deleteAdnetGames(id).then(() => {
          this.tableData.splice(i, 1)
          this.$message.success('删除成功')
        })
      })
    },
    setTableHeader () {
      return 'color: #000'
    }
    // 随机生成数字字母(不区分大小写)
    // createRandomStr (num) {
    //   let str = ''
    //   let range = num
    //   let arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'Xs', 'Y', 'Z']
    //   for (let i = 0; i < range; i++) {
    //     let pos = Math.round(Math.random() * (arr.length - 1))
    //     str += arr[pos]
    //   }
    //   return str
    // }
  }
}
</script>

<style scoped>

</style>
