import api from '@/api/channel'
import store from '@/store/store'

const app = {
  state: {
    device: 'desktop',
    sidebar: {
      opened: store.getSidebarStatus() === 'open',
      withoutAnimation: false
    },
    appMap: new Map(),
    appId: store.getAppId(),
    channelMap: new Map(),
    gatewayUrl: store.getGatewayUrl(),
    routerPrivilege: new Map(),
    channelTypeList: []
  },
  getters: {
    device: state => state.device,
    sidebar: state => state.sidebar,
    isMobile: state => { return state.device === 'mobile' },
    appMap: state => state.appMap,
    appList: state => [...state.appMap.values()],
    appId: state => parseInt(state.appId),
    channelMap: state => state.channelMap,
    channelList: state => [...state.channelMap.values()],
    routerPrivilege: state => state.routerPrivilege,
    channelTypeList: state => state.channelTypeList,
    appInfo: state => state.appMap.get(state.appId) || {},
    appOptions: state => {
      const options = []
      for (const a of state.appMap.values()) {
        options.push({ value: a.id, label: a.name })
      }
      return options
    },
    channelOptions: state => {
      const options = []
      for (const c of state.channelMap.values()) {
        options.push({ value: c.id, label: c.name })
      }
      return options
    }
  },
  mutations: {
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    TOGGLE_SIDEBAR: state => {
      state.sidebar.opened = !state.sidebar.opened
      state.sidebar.withoutAnimation = false
      if (state.sidebar.opened) {
        store.setSidebarStatus('open')
      } else {
        store.setSidebarStatus('close')
      }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      store.setSidebarStatus('close')
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
    },
    SET_APP_MAP: (state, appMap) => {
      state.appMap = appMap
    },
    SET_APP_INFO: (state, appInfo) => {
      const id = appInfo.id
      if (id) {
        state.appMap.set(id, appInfo)
      }
    },
    SET_APP_ID: (state, appId) => {
      state.appId = appId
      store.setAppId(appId)
    },
    SET_CHANNEL_MAP: (state, channelMap) => {
      state.channelMap = channelMap
    },
    SET_CHANNEL_TYPE_LIST: (state, channelTypeList) => {
      state.channelTypeList = channelTypeList
    }
  },
  actions: {
    ToggleDevice: ({ commit }, device) => {
      commit('TOGGLE_DEVICE', device)
    },
    ToggleSideBar: ({ commit }) => {
      commit('TOGGLE_SIDEBAR')
    },
    CloseSideBar: ({ commit }, { withoutAnimation }) => {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    ChangeAppList ({ commit, state }) {
      return new Promise((resolve, reject) => {
        api.getAppData().then(res => {
          const appMap = new Map()
          for (const a of res) {
            appMap.set(a.id, a)
          }
          commit('SET_APP_MAP', appMap)
          let appId = store.getAppId()
          if (!appId) {
            appId = res[res.length - 1].id
          }
          commit('SET_APP_ID', Number(appId))
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    ChangeApp ({ commit, state }, appId) {
      return new Promise((resolve, reject) => {
        commit('SET_APP_ID', appId)
        store.setAppId(appId)
        api.getChannelData(appId).then(res => {
          const channelMap = new Map()
          for (const c of res) {
            channelMap.set(c.id, c)
          }
          commit('SET_CHANNEL_MAP', channelMap)
        })
        resolve()
      })
    },
    UpdateAppInfo ({ commit, state }, appInfo) {
      return new Promise((resolve, reject) => {
        commit('SET_APP_INFO', appInfo)
        resolve()
      })
    },
    GetChannelTypeList ({ commit, state }) {
      return new Promise((resolve, reject) => {
        const channelTypeList = []
        api.getChannelTypeList().then(res => {
          channelTypeList.push(...res)
        })
        commit('SET_CHANNEL_TYPE_LIST', channelTypeList)
        resolve(channelTypeList)
      })
    }
  }
}

export default app
