<template>
  <section class="box">
    <div class="x-args-header">
      <el-button type="primary" @click="handleAdd" size="mini" icon="el-icon-plus"></el-button>
      <p>{{ title }}</p>
      <span></span>
    </div>
    <el-table :data="argsList" border size="mini" style="width: 100%">
<!--      <el-table-column prop="id" label="ID"></el-table-column>-->
      <el-table-column prop="argsId" label="ArgsId" :formatter="argsIdFormatter"></el-table-column>
      <el-table-column prop="logicId" label="LogicId" :formatter="logicIdFormatter"></el-table-column>
      <el-table-column prop="args" label="Args">
        <template slot-scope="scope">
          <el-popover placement="top-start" trigger="click" :content="scope.row.args">
            <div>
              {{ scope.row.args }}
              <el-button type="success" size="mini" circle plain icon="el-icon-copy-document" @click="handleValueCopy(scope.row.args)"></el-button>
            </div>
            <el-button slot="reference" type="text" size="small">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column v-for="item in switchProps" :key="item.prop" :prop="item.prop" :label="item.label">
        <template slot-scope="scope">
          <el-switch v-model="scope.row[item.prop]" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" circle @click="handleEdit(scope.$index, scope.row)" icon="el-icon-edit"></el-button>
          <el-button type="danger" size="mini" circle @click="handleDelete(scope.$index, scope.row)" icon="el-icon-delete"></el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="XS 逻辑参数" :visible.sync="dialogVisible" append-to-body>
      <el-form :model="form" ref="form" label-position="right" label-width="80px">
        <el-form-item label="LogicId" prop="logicId">
          <el-select v-model="form.logicId" filterable placeholder="请选择" style="width: 100%;" @change="handleLogicChange(form.logicId)" clearable>
            <el-option v-for="item in logicMap" :key="item[0]" :label="item[1].logicId + ' - ' + item[1].name" :value="item[1].logicId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-for="item in switchProps" :key="item.prop" :prop="item.prop" :label="item.label">
          <el-switch v-model="form[item.prop]" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </el-form-item>
        <el-form-item label="Args" prop="args">
          <div style="margin-top: 8px; margin-right: 15px; display: flex; justify-content: space-between;">
            <el-button type="success" size="mini" circle plain icon="el-icon-copy-document" @click="handleValueCopy(form.args)"></el-button>
            <el-button type="info" size="mini" circle plain icon="el-icon-view" @click="viewVisible = !viewVisible"></el-button>
          </div>
          <p v-if="viewVisible">{{ form.args }}</p>
          <el-form size="mini">
            <el-form-item v-for="(item, i) in form.args" :key="i" :label="item.label" style="margin-top: 20px">
              <el-input v-model="item.value ">{{item}}</el-input>
              <el-checkbox v-model="item.isKeep" v-if="item.show">是否保留(逻辑模板已无此参数,删除可能会造成一些影响，慎重)</el-checkbox>
            </el-form-item>
          </el-form>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="display: flex; justify-content: space-evenly;">
        <el-button @click="handleFillArgs()" v-if="!isFormAdd">填充参数</el-button>
        <el-button type="primary" @click="handleDialogSave">保 存</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import api from '@/api/channel'

export default {
  name: 'XsLogicArgs',
  props: {
    ownerType: {
      type: Number, // 0=channel, 1=game
      default: 0
    },
    argsId: {
      type: Number,
      default: 0
    },
    logicType: {
      type: Number, // 1=login, 2=pay
      default: 1
    }
  },
  data () {
    return {
      logicMap: new Map(),
      argsList: [],
      dialogVisible: false,
      form: {},
      isFormAdd: true,
      logic: {},
      viewVisible: false
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    gameMap () {
      return this.$store.getters.appMap
    },
    channelMap () {
      return this.$store.getters.channelMap
    },
    title () {
      const owner = this.ownerType === 1 ? '游戏' : '渠道'
      const type = this.logicType === 1 ? 'Login' : 'Pay'
      return `${owner} ${this.argsId} ${type} Args`
    },
    loginSwitchProps () {
      return [
        { prop: 'loginOpen', label: '登录' },
        { prop: 'registerOpen', label: '注册' },
        { prop: 'fastRegisterOpen', label: '快速注册' }
      ]
    },
    paySwitchProps () {
      return [{ prop: 'open', label: '开启' }]
    },
    switchProps () {
      if (this.isLogicTypeLogin()) {
        return this.loginSwitchProps
      }
      return this.paySwitchProps
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    isLogicTypeLogin () {
      return this.logicType === 1
    },
    ifLogicTypePay () {
      return this.logicType === 2
    },
    fetchData () {
      api.getXsLogicData(this.logicType).then(res => {
        const logicMap = new Map()
        for (const item of res) {
          logicMap.set(item.logicId, item)
        }
        this.logicMap = logicMap
      })
      const func = this.isLogicTypeLogin() ? api.getXsArgsLoginList : api.getXsArgsPayList
      func(this.argsId).then(res => {
        this.argsList = res
      })
    },
    getLogic (logicId) {
      return this.logicMap.get(logicId) || {}
    },
    argsIdFormatter (row, col, cellValue) {
      if (this.ownerType === 1) {
        const game = this.gameMap.get(cellValue)
        return game ? cellValue + '-' + game.name : cellValue
      }
      const channel = this.channelMap.get(cellValue)
      return channel ? cellValue + '-' + channel.name : cellValue
    },
    logicIdFormatter (row, col, cellValue) {
      return cellValue + '-' + this.getLogic(cellValue).name
    },
    handleValueCopy (value) {
      navigator.clipboard.writeText(value).then(() => {
        this.$message.success('复制成功')
      })
    },
    handleAdd () {
      this.isFormAdd = true
      this.form = {
        logicId: '',
        type: 0,
        open: true,
        loginOpen: true,
        registerOpen: true,
        fastRegisterOpen: false,
        args: []
      }
      this.dialogVisible = true
      this.tableDataIndex = -1
    },
    handleEdit (index, row) {
      this.isFormAdd = false
      this.tableDataIndex = index
      const obj = JSON.parse(JSON.stringify(row))
      obj.args = JSON.parse(obj.args || '{}')
      obj.args = this.mapLogicArgs(JSON.parse(this.getLogic(obj.logicId).args), obj.args)
      this.form = obj
      this.dialogVisible = true
    },
    // 返回值 [ { "label": "A", "key": "a", "value": "1" }, { "label": "B", "key": "b", "value": "2" }, { "label": "C", "key": "c" } ]
    mapLogicArgs (arr, obj) {
      // arr是模版数组 [{"label":"A","key":"a","value":"a1"},{"label":"B","key":"b","value":"b1"}]
      // obj是参数对象 { "a": "1", "b": "2" }
      const arrMap = new Map()
      for (const item of arr) {
        arrMap.set(item.key, item)
        item.value = obj[item.key]
      }
      for (const item in obj) {
        if (!arrMap.has(item)) {
          arr.push({ key: item, value: obj[item], label: item, isKeep: true, show: true })
        }
      }
      return arr
    },
    // 填充参数
    handleFillArgs () {
      const args = this.getLogic(this.form.logicId).args
      const logicTemplate = args ? JSON.parse(args) : null
      const logicTemplateMap = new Map()
      for (const item of logicTemplate) {
        logicTemplateMap.set(item.key, item)
      }
      if (logicTemplate) {
        for (const item of this.form.args) {
          if (!item.value) {
            item.value = logicTemplateMap.get(item.key).value
          }
        }
      }
    },
    handleLogicChange (logicId) {
      const logic = this.getLogic(logicId)
      this.form.args = JSON.parse(logic.args || '[]')
    },
    handleDialogSave () {
      const data = JSON.parse(JSON.stringify(this.form))
      const args = {}
      for (const item of data.args) {
        args[item.key] = item.value
        if (Object.hasOwn(item, 'isKeep') && !item.isKeep) {
          delete args[item.key]
        }
      }
      data.ownerType = this.ownerType
      data.argsId = this.argsId
      data.args = JSON.stringify(args)
      let func
      if (this.isLogicTypeLogin()) {
        func = this.isFormAdd ? api.addXsArgsLogin : api.updateXsArgsLogin
      } else {
        func = this.isFormAdd ? api.addXsArgsPay : api.updateXsArgsPay
      }
      func(data).then(res => {
        this.dialogVisible = false
        this.$message.success(this.isFormAdd ? '添加成功' : '修改成功')
        this.$refs.form.clearValidate()
        this.fetchData()
      })
    },
    handleDelete (index, row) {
      this.$confirm('是否永久删除该数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const func = this.isLogicTypeLogin() ? api.deleteXsArgsLogin : api.deleteXsArgsPay
        func(row.id).then(res => {
          this.argsList.splice(index, 1)
          this.$message.success('删除成功')
        })
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    }
  }
}
</script>

<style scoped>
.x-args-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>
