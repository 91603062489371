<template>
  <section>
    <el-button v-if="channelId!==argsId" type="text" :disabled="true">{{argsId}}</el-button>
    <el-button v-else type="text" @click="handleDisplay" size="mini">
      <font-awesome-icon :icon="buttonIcon"/> {{argsId}}
    </el-button>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="60%" append-to-body>
      <logic-args :owner-type="ownerType" :args-id="argsId" :logic-type="logicType"></logic-args>
    </el-dialog>
  </section>
</template>

<script>
import LogicArgs from '@/views/Xs/Args/LogicArgs.vue'

export default {
  name: 'XsLogicArgsDialog',
  components: { LogicArgs },
  props: {
    ownerType: {
      type: Number, // 0=channel, 1=game
      default: 0
    },
    argsId: {
      type: Number,
      default: 0
    },
    logicType: {
      type: Number, // 1=login, 2=pay
      default: 1
    },
    channelId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    dialogTitle () {
      const owner = this.ownerType === 1 ? '游戏' : '渠道'
      const type = this.logicType === 1 ? 'Login' : 'Pay'
      return `${type} Args - ${owner} ${this.channelId}`
    },
    buttonIcon () {
      return this.logicType === 1 ? 'fa-l' : 'fa-p'
    }
  },
  data () {
    return {
      dialogVisible: false
    }
  },
  mounted () {
  },
  methods: {
    handleDisplay () {
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped>
</style>
