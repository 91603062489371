<template>
  <el-dialog title="商户组管理" :visible.sync="getVisible">
    <el-form :model="form" :rules='rules' ref='form' label-position='right' label-width="120px">
      <el-form-item label='ID' prop='id'>
        <el-input type="number" v-model.number='form.id' style='width: 100%' :disabled="type === 'edit'" ></el-input>
      </el-form-item>
      <el-form-item label='商户类型' prop='type'>
        <el-select v-model="form.type" style="width: 100%;" @change="typeChange(form.type)">
          <el-option v-for="item in merchantType" :key="item.id" :label="`${item.value} - ${item.label}`" :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item label='商户ID' prop='appId'>
        <el-select size="small" v-model="form.appId" style="width: 100%;" @change="mchAppIdChange(form.appId)">
          <el-option v-for="item in merchantList" :key="item.id" :label="`${item.label}`" :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item label='商户组名称' prop='name'>
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label='随机开关' prop='open'>
        <el-switch v-model="form.open" />
      </el-form-item>
      <el-form-item label='随机商户' prop='data'>
        <el-button size="mini" type="primary" @click="form.data.push({})">+新增</el-button>
        <el-table :data="form.data" border size="mini" style="width: 100%">
          <el-table-column align="center" prop="id" label="XP-商户ID">
            <el-select size="small" slot-scope="{row}" v-model="row.appId">
              <el-option v-for="item in merchantList" :key="item.id" :label="`${item.label}`" :value="item.value"/>
            </el-select>
          </el-table-column>
          <el-table-column align="center" prop="weight" label="权重">
            <template slot-scope="{row}">
              <el-input-number size="small" v-model="row.weight" placeholder="" />
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" label="操作" width="100">
            <template slot-scope="{$index}">
              <el-button type="info" size="mini" circle @click="$delete(form.data, $index)" icon="el-icon-delete"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style='text-align: left'>
      <el-button size="small" type="primary" @click="onSubmit">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Api from '../../api/channel'
export default {
  name: 'MchGroupDialog',
  props: { visible: Boolean, formData: Object, type: String, updateFun: { type: Function, default: () => () => {} } },
  data () {
    return {
      merchantType: [
        { value: 1, label: '微信' },
        { value: 2, label: '支付宝' }
      ],
      merchantList: [],
      form: {}
    }
  },
  computed: {
    getVisible: {
      get () { return this.visible },
      set (val) { return this.$emit('update:visible', val) }
    },
    rules () {
      return {
        id: [{ type: 'number', required: true, message: '请输入数字，且不能为空' }],
        name: [{ required: true, message: '请输入商户组名称' }],
        type: [{ required: true, message: '请选择商户类型' }],
        appId: [{ required: true, message: '请选择商户ID' }],
        data: [{ validator: this.dataValidator, trigger: 'change' }]
      }
    }
  },
  watch: {
    formData: {
      async handler (nVal) {
        const form = JSON.parse(JSON.stringify(nVal))
        if (nVal.type) await this.getMerchantList(nVal.type)

        this.form = { ...form, data: form.data ?? [], open: !!form.open }
      },
      deep: true
    },
    visible (nVal) {
      if (!nVal) {
        const data = this.$options.data()
        this.merchantList = data.merchantList
      }
    }
  },
  methods: {
    dataValidator (_rule, value, cb) {
      // callback(new Error('请再次输入密码'));
      const { open } = this.form
      if (open) {
        if (!value.length) return cb(new Error('随机商户不能为空 !'))
      }
      cb()
    },
    mchAppIdChange (id) {
      const obj = this.merchantList.find((item) => item.value === id)
      // this.form.name = obj.label
      this.$set(this.form, 'name', obj.label)
      // const open = this.form.open ? '1' : '0'
      // this.form.name = `[${open}] ${obj.label}`
    },
    typeChange (type) {
      this.$set(this.form, 'data', [])
      this.$set(this.form, 'appId', '')

      this.getMerchantList(type)
    },
    async getMerchantList (type) {
      const hashRequest = {
        1: Api.getMchWxList,
        2: Api.getMchAliList
      }
      // const hashType = this.merchantType.reduce((pre, cur) => {
      //   return { ...pre, [cur.value]: cur.label }
      // }, {})
      if (Reflect.has(hashRequest, type)) {
        await hashRequest[type]().then(res => {
          this.merchantList = res.reduce((pre, cur) => {
            // return [...pre, { ...cur, label: `[${hashType[type]}] ${cur.appId} - ${cur.name}`, value: cur.appId }]
            return [...pre, { ...cur, label: `${cur.appId} - ${cur.name}`, value: cur.appId }]
          }, [])
        })
      }
    },
    onSubmit () {
      this.$refs.form.validate((valid) => {
        if (!valid) return false

        const hashRequest = {
          add: Api.addMchGroup,
          edit: Api.changeMchGroup
        }
        if (Reflect.has(hashRequest, this.type)) {
          const { data, open } = this.form
          hashRequest[this.type]({ ...this.form, open: +open, data: JSON.stringify(data) }).then(() => {
            const successType = { add: '新增成功！', edit: '修改成功！' }
            this.$message.success(successType[this.type])
            this.getVisible = false
            this.updateFun()
          })
        }
      })
    }
  }
}
</script>

<style>
</style>
