<template>
  <rest
    :remote="remote"
    :table-columns="tableColumns"
    :formInitialize="formInitialize"
    :form-rules="formRules"
    :exportable="true"
    :create-able="true" :update-able="true" :delete-able="true"
  >
  </rest>
</template>

<script>

import Rest from '@/components/Spring/Rest'

export default {
  name: 'xsArgsLogins',
  components: { Rest },

  data () {
    return {
      remote: {
        path: 'channel',
        resource: this.$options.name,
        realtime: true
      },
      formInitialize: {
        args: [],
        open: true,
        debug: true,
        timeout: 3000
      },
      formRules: {
        ownerType: [{ required: true, message: '请填写 ownerType' }],
        argsId: [{ required: true, message: '请填写 argsId' }],
        logicId: [{ required: true, message: '请填写 logicId' }],
        name: [{ required: true, message: '请填写 name' }],
        args: [{ required: true, message: '请填写 args' }],
        open: [{ required: true, message: '请填写 open' }],
        debug: [{ required: true, message: '请填写 debug' }],
        timeout: [{ required: true, message: '请填写 timeout' }]
      }
    }
  },
  computed: {
    tableColumns () {
      return [
        // { label: 'ID', prop: 'id', hiddenForm: true },
        {
          label: '所属类型',
          prop: 'ownerType',
          type: 'select',
          options: [
            { value: 0, label: '渠道' },
            { value: 1, label: '游戏' }
          ]
        },
        { label: 'ArgsId', prop: 'argsId' },
        { label: 'LogicId', prop: 'logicId' },
        { label: 'Name', prop: 'name' },
        { label: 'Args', prop: 'args', type: 'textarea' },
        { label: 'Open', prop: 'open', type: 'switch' },
        { label: 'Debug', prop: 'debug', type: 'switch' },
        { label: 'Timeout', prop: 'timeout' }
      ]
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style scoped>
</style>
