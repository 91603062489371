<template>
  <rest
    :remote="remote"
    :table-columns="tableColumns"
    :form-initialize="formInitialize"
    :form-rules="formRules"
    :exportable="true"
    :create-able="true" :update-able="true" :delete-able="true"
  >
  </rest>
</template>

<script>

import Rest from '@/components/Spring/Rest'

export default {
  name: 'whitelists',
  components: { Rest },

  data () {
    return {
      remote: {
        path: 'ufo',
        resource: this.$options.name,
        realtime: true
      },
      // formInitialize: {},
      formRules: {
        appId: [{ required: true, trigger: ['submit', 'blur'], message: '请填写游戏ID' }],
        open: [{ required: true, trigger: ['submit', 'blur'], message: '请选择是否开启' }],
        reason: [{ required: true, message: '请填写原因' }],
        ruleKey: [{ required: true, message: '请填写规则密钥' }],
        ruleType: [{ required: true, trigger: ['submit', 'blur'], message: '请填写规则类型' }],
        select: [{ required: true, message: '请选择作用域类型' }],
        expired: [{ required: false }]
      },
      listeners: {
      }
    }
  },
  computed: {
    formInitialize () {
      return {
        appId: this.appId
      }
    },
    appId () {
      return this.$store.getters.appId
    },
    tableColumns () {
      return [
        { label: 'ID', prop: 'id', hiddenForm: true },
        {
          label: '游戏ID',
          prop: 'appId'
          // type: 'select',
          // options: this.appIdTableOptions
        },
        {
          label: '是否开启',
          prop: 'open',
          type: 'select',
          options: [
            { value: 0, label: '否' },
            { value: 1, label: '是' }
          ]
        },
        { label: '原因', prop: 'reason' },
        { label: '规则密钥', prop: 'ruleKey', type: 'textarea' },
        {
          label: '规则类型',
          prop: 'ruleType',
          type: 'select',
          options: [
            { value: 1, label: '用户' },
            { value: 2, label: 'ip' },
            { value: 3, label: '设备' }
          ]
        },
        {
          label: '作用域',
          prop: 'scopeType',
          type: 'select',
          options: [
            { value: 1, label: '封禁' },
            { value: 2, label: '注册' },
            { value: 3, label: '充值' }
          ]
        }
        // { label: '是否过期', prop: 'expired', type: 'switch' }
      ]
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style scoped>
</style>
