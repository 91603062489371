<template>
  <section>
    <el-popover :trigger="trigger" :placement="placement" :title="title">
      <ext-text :content="content"/>
      <el-button slot="reference" :size="btnSize" v-if="isBtnShow">{{ btnLabel }}</el-button>
    </el-popover>
  </section>
</template>

<script>
import ExtText from '@/components/ExtText/index.vue'

export default {
  name: 'ExtTextPopover',
  components: { ExtText },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    btnShow: {
      type: Boolean,
      default: true
    },
    btnLabel: {
      type: String,
      default: '查看'
    },
    btnSize: {
      type: String,
      default: 'mini'
    }
  },
  computed: {
    isBtnShow () {
      return !!this.value && !!this.content
    }
  }
}
</script>

<style scoped>

</style>
