<template>
  <rest
    :remote="remote"
    :table-columns="tableColumns"
    :formInitialize="formInitialize"
    :form-rules="formRules"
    :exportable="true"
    :create-able="true" :update-able="true" :delete-able="true"
  >
  </rest>
</template>

<script>

import Rest from '@/components/Spring/Rest'

export default {
  name: 'telecomAuths',
  components: { Rest },

  data () {
    return {
      remote: {
        path: 'channel',
        resource: this.$options.name,
        realtime: false,
        reloadUrl: '/channel/admin/cache/reload/telecom/auth'
      },
      formRules: {
        gameId: [{ required: true, message: '请选择 游戏ID' }],
        pkgName: [{ required: true, message: '请填写 包名' }],
        appId: [{ required: true, message: '请填写 appId' }],
        appSecret: [{ required: true, message: '请填写 appSecret' }],
        publicKey: [{ required: true, message: '请填写 publicKey' }]
      },
      listeners: {
      }
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    appOptions () {
      return this.$store.getters.appOptions
    },
    formInitialize () {
      return {
        gameId: this.appId
      }
    },
    tableColumns () {
      return [
        { label: 'ID', prop: 'id', hiddenForm: true },
        { label: '游戏', prop: 'gameId', type: 'select', options: this.appOptions },
        { label: '包名', prop: 'pkgName' },
        { label: 'AppId', prop: 'appId' },
        { label: 'AppSecret', prop: 'appSecret' },
        { label: 'PublicKey', prop: 'publicKey', type: 'textarea' }
      ]
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style scoped>
</style>
