<template>
  <section>
    <div>{{ content }}
      <el-button type="success" size="mini" circle plain icon="el-icon-copy-document" @click="handleCopy"></el-button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ExtText',
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleCopy () {
      navigator.clipboard.writeText(this.content).then(() => {
        this.$message.success('复制成功')
      })
    }
  }
}
</script>

<style scoped>

</style>
