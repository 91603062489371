<template>
  <rest
    :remote="remote"
    :table-columns="tableColumns"
    :formInitialize="formInitialize"
    :form-rules="formRules"
    :exportable="true"
    :create-able="true" :update-able="true" :delete-able="true"
  >
  </rest>
</template>

<script>
import Rest from '@/components/Spring/Rest.vue'
export default {
  name: 'wikiApps',
  components: { Rest },

  data () {
    return {
      remote: {
        path: 'channel',
        resource: this.$options.name,
        realtime: true
      },
      formInitialize: {
        id: 1,
        project: '',
        name: '',
        port: 10010,
        proxyPort: 9010
      },
      formRules: {
        id: [{ type: 'number', required: true, message: '请填写 应用ID' }],
        project: [{ required: true, message: '请填写 project名称' }],
        name: [{ required: true, message: '请填写 名称' }],
        port: [{ type: 'number', required: true, message: '请填写 应用端口' }],
        proxyPort: [{ type: 'number', required: true, message: '请填写 代理端口' }]
      }
    }
  },
  computed: {
    tableColumns () {
      return [
        {
          label: '应用ID',
          prop: 'id',
          disabled: true,
          type: 'number',
          min: 1,
          max: 999,
          change (form) {
            const id = form.id
            form.port = 10000 + 10 * id
            form.proxyPort = 9000 + 10 * id
          }
        },
        {
          label: 'Project',
          prop: 'project',
          change (form) {
            if (!form.name) {
              form.name = form.project
            }
          }
        },
        { label: '名称', prop: 'name' },
        { label: '应用端口', prop: 'port', type: 'number', min: 1024, max: 65535 },
        { label: '代理端口', prop: 'proxyPort', type: 'number', min: 1024, max: 65535 },
        { label: '备注', prop: 'note', type: 'textarea' }
        // { label: '创建时间', prop: 'createdAt', type: 'datetime', hiddenForm: true },
        // { label: '修改时间', prop: 'updatedAt', type: 'datetime', hiddenForm: true }
      ]
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
