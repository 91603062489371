<template>
  <rest
    :remote="remote"
    :table-columns="tableColumns"
    :formInitialize="formInitialize"
    :form-rules="formRules"
    :exportable="true"
    :create-able="true" :update-able="true" :delete-able="true"
  >
  </rest>
</template>

<script>
import Rest from '@/components/Spring/Rest.vue'
export default {
  name: 'prefixes',
  components: { Rest },

  data () {
    return {
      remote: {
        path: 'short-url',
        resource: this.$options.name,
        realtime: true
      },
      formInitialize: {},
      formRules: {
        prefix: [{ required: false, message: '请填写 短链前缀' }],
        host: [{ type: 'url', required: true, message: '请填写 短链域名' }],
        redirectUrl: [{ type: 'url', required: true, message: '请填写 默认跳转地址' }],
        note: [{ required: false, message: '请填写 备注' }]
      }
    }
  },
  computed: {
    tableColumns () {
      return [
        { label: 'ID', prop: 'id', hiddenForm: true },
        { label: '短链前缀', prop: 'prefix' },
        { label: '短链域名', prop: 'host' },
        { label: '默认跳转地址', prop: 'redirectUrl' },
        { label: '备注', prop: 'note' }
        // { label: '创建时间', prop: 'createdAt', type: 'datetime', hiddenForm: true },
        // { label: '修改时间', prop: 'updatedAt', type: 'datetime', hiddenForm: true }
      ]
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
