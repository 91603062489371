<template>
  <rest
    :remote="remote"
    :table-columns="tableColumns"
    :formInitialize="formInitialize"
    :form-rules="formRules"
    :exportable="true"
    :create-able="true" :update-able="true" :delete-able="true"
  >
  </rest>
</template>

<script>

import Rest from '@/components/Spring/Rest'

export default {
  name: 'mchWxes',
  components: { Rest },

  data () {
    return {
      remote: {
        path: 'channel',
        resource: this.$options.name,
        realtime: false,
        reloadUrl: '/channel/admin/cache/reload/mch/wx'
      },
      formInitialize: {},
      formRules: {
        name: [{ required: true, message: '请填写名称' }],
        appId: [{ required: true, message: '请填写应用ID' }],
        mchId: [{ required: true, message: '请填写商户ID' }],
        mchName: [{ required: true, message: '请填写商户名称' }],
        apiV2Key: [{ required: false, message: '请填写APIv2密钥' }],
        apiV3Key: [{ required: false, message: '请填写APIv3密钥' }],
        apiPrivateKey: [{ required: false, message: '请填写API私钥' }],
        publicKey: [{ required: false, message: '请填写微信支付公钥' }],
        publicKeyId: [{ required: false, message: '请填写微信支付公钥Id' }],
        apiCert: [{ required: false, message: '请填写API证书' }],
        apiSerialNumber: [{ required: false, message: '请填写API证书序列号' }],
        notifyUrl: [{ type: 'url', required: true, message: 'NotifyUrl格式不正确' }],
        payDomain: [{ type: 'url', required: false, message: '支付域 URL格式不正确' }]
      }
    }
  },
  computed: {
    tableColumns () {
      return [
        { label: 'ID', prop: 'id', hiddenForm: true },
        { label: '名称', prop: 'name' },
        { label: '应用ID', prop: 'appId' },
        { label: '商户ID', prop: 'mchId' },
        { label: '商户名称', prop: 'mchName' },
        { label: 'APIv2密钥', prop: 'apiV2Key' },
        { label: 'APIv3密钥', prop: 'apiV3Key' },
        { label: 'API私钥', prop: 'apiPrivateKey', type: 'textarea', placeholder: 'API私钥 apiclient_key.pem' },
        { label: 'API证书', prop: 'apiCert', type: 'textarea', placeholder: 'API证书 apiclient_cert.pem' },
        { label: 'API证书序列号', prop: 'apiSerialNumber' },
        { label: '微信支付公钥', prop: 'publicKey', type: 'textarea', placeholder: '微信支付公钥 pub_key.pem' },
        { label: '微信支付公钥Id', prop: 'publicKeyId' },
        { label: 'NotifyUrl', prop: 'notifyUrl', placeholder: 'NotifyUrl 支付回调通知地址' },
        { label: '支付域', prop: 'payDomain', placeholder: '支付域 格式 https://sdk.youpin7.com/' }
      ]
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style scoped>
</style>
