<template>
  <section>
    <div class="box">
<!--      <h3 style='text-align: left;margin: 0'>广告渠道</h3>-->
      <div style="display: flex;justify-content: space-between;">
        <el-button type='primary' @click='handleAddAdnetChannel'>新增</el-button>
        <el-input v-model="search" placeholder="输入关键字搜索" style="width: 180px"></el-input>
      </div>
    </div>
<!--    主体内容    -->
    <div class="box">
      <el-table :data='tables' style='width: 100%' stripe :header-cell-style='setTableHeader'>
        <el-table-column prop='appId' label='应用ID'> </el-table-column>
        <el-table-column prop='channelId' label='渠道ID'> </el-table-column>
        <el-table-column prop='adnetType' label='广告类型' :formatter="adnetTypeFormatter"></el-table-column>
        <el-table-column prop='argsGroupId' label='参数组ID'> </el-table-column>
        <el-table-column prop='poses' label='广告位'>
          <template slot-scope='scope'>
            <el-button round type='text' @click='handleChangeAdnetChannel(scope.row)'>特殊配置</el-button>
          </template>
        </el-table-column>
        <el-table-column label='操作' align='right' >
          <template slot-scope='scope'>
            <el-button round type='primary' size='mini' @click='handleChangeAdnetChannel(scope.row)' circle icon='el-icon-edit'></el-button>
            <el-button round type='info' size='mini' @click='handleDeleteChannel(scope.row.channelId, scope.$index)' icon='el-icon-delete' circle></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
<!--    dialog    -->
    <el-dialog title='广告渠道' :visible='adnetDialog' @close ='adnetDialog=false' label-position='left' top='4vh' width="70%">
      <el-form :model='form' label-position='right' label-width="80px" :rules='formRules' ref='form'>
        <el-form-item label='应用ID' prop='appId'>
          <el-input v-model.number='form.appId' :disabled='type' style='width: 100%' ></el-input>
        </el-form-item>
        <el-form-item label='渠道ID' prop='channelId'>
          <el-select v-model='form.channelId' collapse-tags style='width: 100%' placeholder='--请选择渠道--' clearable filterable :disabled="type">
            <el-option v-for='item in channelOptions' :key='item.id' :label='item.id + "--" + item.name' :value='item.id' :disabled="channelFilter(item.id)"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='广告类型' prop='adnetType'>
          <el-select v-model='form.adnetType' collapse-tags style='width: 100%' placeholder='--请选择广告类型--' clearable filterable>
            <el-option v-for='item in adnetType' :key='item.value' :label='item.label' :value='item.value'> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='参数组Id' prop='argsGroupId'>
          <el-input v-model.number='form.argsGroupId' style='width: 100%' ></el-input>
        </el-form-item>
        <el-form-item label="广告位">
          <el-form :model='form' size='mini'>
            <el-form-item style="margin-top: 20px">
              <!--表头-->
              <el-form-item style='float: left;margin-bottom: 5px;width:100%'>
                <el-row :gutter='10' style='text-align: left;font-weight: bold'>
                  <el-col :span='2'><span alt="广告位ID">广告位ID</span></el-col>
                  <el-col :span='3'><span>广告位名称</span></el-col>
                  <el-col :span='3'><span>奖励名称</span></el-col>
                  <el-col :span='2'><span>奖励数量</span></el-col>
                  <el-col :span='3'><span>穿山甲广告位ID</span></el-col>
                  <el-col :span='3'><span>穿山甲Secret</span></el-col>
                  <el-col :span='3'><span>优量汇广告位ID</span></el-col>
                  <el-col :span='3'><span>优量汇Secret</span></el-col>
                  <el-col :span='2'><span>操作</span></el-col>
                </el-row>
              </el-form-item>
              <!--参数-->
              <el-form-item v-for='(item, i) in form.poses' :key='i' style='float: left;margin-bottom: 5px;width:100%'>
                <el-row :gutter='10'>
                  <el-col :span='2'><el-input v-model='item.posId' placeholder='广告位ID'> </el-input></el-col>
                  <el-col :span='3'><el-input v-model='item.posName' placeholder='广告位名称'> </el-input></el-col>
                  <el-col :span='3'><el-input v-model='item.rewardName' placeholder='奖励名称'> </el-input></el-col>
                  <el-col :span='2'><el-input v-model='item.rewardAmount' placeholder='奖励数量'> </el-input></el-col>
                  <el-col :span='3'><el-input v-model='item.pangolinPosId' placeholder='穿山甲广告位ID'> </el-input></el-col>
                  <el-col :span='3'><el-input v-model='item.pangolinAppSecret' placeholder='穿山甲Secret'> </el-input></el-col>
                  <el-col :span='3'><el-input v-model='item.tencentPosId' placeholder='优量汇广告位ID'> </el-input></el-col>
                  <el-col :span='3'><el-input v-model='item.tencentSecret' placeholder='优量汇Secret'> </el-input></el-col>
                  <el-col :span='2'><el-button type='info' size='mini' circle icon="el-icon-delete" @click='handleDeleteParams(i)'> </el-button></el-col>
                </el-row>
              </el-form-item>
              <el-form-item style='float: left'>
                <el-button type='primary' size='mini' @click='handleAddParams'>添加</el-button>
              </el-form-item>
            </el-form-item>
          </el-form>
        </el-form-item>
      </el-form>
      <div slot='footer' class='dialog-footer' style='text-align: left'>
        <el-button type='primary' @click='handleSaveDialog'>保 存</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import api from '@/api/channel'
import options from '@/common/options'
import f from '@/filter'

export default {
  name: 'index',
  components: { },
  created () {
    this.fetchData()
    this.fetchChannelList()
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    tables () {
      const search = this.search
      if (search) {
        return this.tableData.filter(data => {
          return Object.keys(data).some(key => {
            return String(data[key]).toLowerCase().indexOf(search) > -1
          })
        })
      }
      return this.tableData
    }
  },
  watch: {
    appId (val, oldVal) {
      if (val === oldVal) {
        return
      }
      this.fetchData()
      this.fetchChannelList()
    }
  },
  data () {
    return {
      tableData: [],
      search: '',
      form: {
        poses: [{ posId: '', posName: '', rewardName: '', rewardAmount: '', pangolinPosId: '', pangolinAppSecret: '', tencentPosId: '', tencentSecret: '' }]
      },
      channelOptions: [],
      type: false, // true为修改,false为新增
      dialogVisible: false,
      adnetDialog: false,
      adnetType: options.adnetType,
      formRules: {
        appId: { type: 'number', required: true, message: '请输入id，且不能为空', trigger: 'change' },
        channelId: { type: 'number', required: true, message: '请输入channelId，且不能为空', trigger: 'change' },
        argsGroupId: { type: 'number', required: true, message: '请输入argsGroupId，且不能为空', trigger: 'change' }
      }
    }
  },
  methods: {
    fetchData () {
      const params = { appId: this.appId }
      // 获取已配置渠道
      api.getAdnetChannels(params).then(res => {
        this.tableData = res
      })
    },
    // 获取渠道列表
    fetchChannelList () {
      api.getChannelData(this.appId).then(res => {
        this.channelOptions = Object.assign([], res)
      })
    },
    // 新增渠道广告
    handleAddAdnetChannel () {
      api.getAdnetGames(this.appId).then(res => {
        const poses = res.poses ? JSON.parse(res.poses) : [{ posId: '', posName: '', rewardName: '', rewardAmount: '', pangolinPosId: '', pangolinAppSecret: '', tencentPosId: '', tencentSecret: '' }]
        this.form = { poses }
        this.adnetDialog = true
        this.type = false
        this.form.appId = this.appId
      }).catch(() => {
        this.$message.error('请先去 广告应用 配置模板')
      })
    },
    // 修改渠道广告
    handleChangeAdnetChannel (row) {
      const data = Object.assign({}, row)
      data.poses = data.poses.length ? JSON.parse(row.poses) : [{ posId: '', posName: '', rewardName: '', rewardAmount: '', pangolinPosId: '', pangolinAppSecret: '', tencentPosId: '', tencentSecret: '' }]
      this.form = data
      this.adnetDialog = true
      this.type = true
    },
    // 渠道广告 删除
    handleDeleteChannel (id, i) {
      this.$confirm('确定删除吗?', '提示', { type: 'warning' }).then(() => {
        api.deleteAdnetChannels(id).then(() => {
          this.tableData.splice(i, 1)
          this.$message.success('删除成功')
        })
      })
    },
    // 添加参数
    handleAddParams () {
      const params = { posId: '', posName: '', rewardName: '', rewardAmount: '', pangolinPosId: '', pangolinAppSecret: '', tencentPosId: '', tencentSecret: '' }
      if (!this.form.poses) {
        this.form.poses = []
      }
      this.form.poses.push(params)
    },
    // 删除参数
    handleDeleteParams (i) {
      this.form.poses.splice(i, 1)
    },
    // 保存
    handleSaveDialog () {
      // 先进行表单验证
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = Object.assign({}, this.form)
          if (params.poses) {
            let len = params.poses.length
            const arr = params.poses
            while (len--) {
              if (arr[len].posId === '') {
                arr.splice(len, 1)
              }
            }
          }
          params.poses = params.poses && params.poses.length > 0 ? JSON.stringify(params.poses) : null
          // true 为修改 false 新增
          const f = this.type ? api.putAdnetChannels : api.postAdnetChannels
          f(params).then(() => {
            this.$message.success(this.type ? '修改成功' : '添加成功')
            this.adnetDialog = false
            this.fetchData()
          })
        } else {
          return false
        }
      })
    },
    productsToStr (products) {
      return f.productsToStr(products)
    },
    // 渠道是否可选
    channelFilter (channelId) {
      return this.tableData.some(item => { return item.channelId === channelId })
    },
    adnetTypeFormatter (row) {
      for (const item of options.adnetType) {
        if (item.value === row.adnetType) {
          return item.label
        }
      }
    },
    setTableHeader () {
      return 'color: #000'
    }
  }
}
</script>

<style scoped>
  e_card {
    margin-bottom: 20px;
  }
</style>
