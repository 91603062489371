<template>
  <rest
    :remote="remote"
    :table-columns="tableColumns"
    :formInitialize="formInitialize"
    :form-rules="formRules"
    :exportable="true"
    :create-able="true" :update-able="true" :delete-able="true"
  >
  </rest>
</template>

<script>

import Rest from '@/components/Spring/Rest'

export default {
  name: 'companies',
  components: { Rest },

  data () {
    return {
      remote: {
        path: 'channel',
        resource: this.$options.name,
        realtime: true
      },
      formInitialize: {},
      formRules: {
        id: [{ required: true, message: '请填写主体ID' }],
        name: [{ required: true, message: '请填写名称' }]
      }
    }
  },
  computed: {
    tableColumns () {
      return [
        { label: 'ID', prop: 'id', disabled: true },
        { label: '名称', prop: 'name' },
        { label: '创建时间', prop: 'createdAt', type: 'datetime', hiddenForm: true },
        { label: '修改时间', prop: 'updatedAt', type: 'datetime', hiddenForm: true }
      ]
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style scoped>
</style>
