<template>
  <section>
    <div class="box" style="display: flex; justify-content: space-between; align-items: center;">
      <cache-reload reload-type="xsArgsLogin" btn-label="重载LoginArgs" btn-icon="el-icon-user"/>
      <cache-reload reload-type="channel" btn-label="重载Channel" btn-type="info"/>
      <cache-reload reload-type="xsArgsPay" btn-label="重载PayArgs" btn-icon="el-icon-shopping-bag-2"/>
    </div>
    <div class="box">
      <el-table :data="channelList" style="width: 100%" stripe>
        <el-table-column prop="id" label="渠道ID"> </el-table-column>
        <el-table-column prop="name" label="渠道名称"></el-table-column>
        <el-table-column prop="accountChannelId" label="账号渠道ID"> </el-table-column>
        <el-table-column prop="xsType" label="XsType"></el-table-column>
        <el-table-column prop="xsLoginArgsId" label="登录参数">
          <template slot-scope="scope">
            <logic-args-dialog :owner-type="0" :args-id="scope.row.xsLoginArgsId" :logic-type="1" :channel-id="scope.row.id"></logic-args-dialog>
          </template>
        </el-table-column>
        <el-table-column prop="xsPayArgsId" label="支付参数" width="120">
          <template slot-scope="scope">
            <logic-args-dialog :owner-type="0" :args-id="scope.row.xsPayArgsId" :logic-type="2" :channel-id="scope.row.id"></logic-args-dialog>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="right" width="120">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleEditXsArgsId(scope.$index, scope.row)" icon="el-icon-edit" circle></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog title="更改XsArgsId" :visible.sync="dialogVisible" @close="handleCloseDialog" width="60%" append-to-body>
        <el-form :model="form" ref="form" :rules="rules" label-width="120px">
          <el-form-item prop="channelId" label="渠道号">
            <el-input v-model.number="form.channelId" placeholder="channelId" disabled></el-input>
          </el-form-item>
          <el-form-item prop="xsLoginArgsId" label="登录参数">
            <el-input v-model.number="form.xsLoginArgsId" placeholder="请填写XsArgs的渠道号" clearable></el-input>
          </el-form-item>
          <el-form-item prop="xsPayArgsId" label="支付参数">
            <el-input v-model.number="form.xsPayArgsId" placeholder="请填写XsArgs的渠道号" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSubmitXsArgsId">提交</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import LogicArgsDialog from '@/views/Xs/Args/LogicArgsDialog.vue'
import CacheReload from '@/components/CacheReload/index.vue'
import api from '@/api/channel'

export default {
  name: 'XsArgsChannel',
  components: { LogicArgsDialog, CacheReload },
  data () {
    const xsArgsIdRules = (rule, value, callback) => {
      // 允许不填
      if (!value) {
        callback()
        return
      }
      const min = this.appId * 1000
      const max = (this.appId + 1) * 1000
      if (typeof (value) !== 'number') {
        callback(new Error('请输入数字'))
      } else if (value < min) {
        callback(new Error('应大于等于' + min))
      } else if (value >= max) {
        callback(new Error('应小于' + max))
      } else {
        callback()
      }
    }
    return {
      xsType: 1,
      channelList: [],
      dialogVisible: false,
      tableDataIndex: -1,
      form: {},
      rules: {
        xsLoginArgsId: [{ required: false, validator: xsArgsIdRules, trigger: 'blur' }],
        xsPayArgsId: [{ required: false, validator: xsArgsIdRules, trigger: 'blur' }]
      }
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      api.getXsTypeChannels(this.appId, this.xsType).then(res => {
        this.channelList = res
      })
    },
    handleEditXsArgsId (index, row) {
      this.dialogVisible = true
      this.tableDataIndex = index
      this.form = {
        channelId: row.id,
        xsLoginArgsId: row.xsLoginArgsId,
        xsPayArgsId: row.xsPayArgsId
      }
    },
    handleCloseDialog () {
      this.tableDataIndex = -1
      this.$refs.form.clearValidate()
      this.form = {}
      this.dialogVisible = false
    },
    handleSubmitXsArgsId () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          api.updateChannelXsArgsId(this.form).then(res => {
            const { code, msg, data } = res
            if (code === 200 && data) {
              this.channelList.splice(this.tableDataIndex, 1, data)
              this.$message.success('修改成功')
              this.handleCloseDialog()
            } else {
              this.$message.error(`修改失败: ${msg}`)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
