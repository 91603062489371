<template>
  <section>
    <div class="box">
      <div style="display: flex;justify-content: space-between;">
        <el-button type='primary' @click='handleAddLogic'>新增</el-button>
        <el-input v-model="lgcName" @input="lgcNameChange"  placeholder="输入关键字搜索" style="width: 180px"></el-input>
      </div>
    </div>
    <!--    主体内容-->
    <div class="box">
      <!-- 分页器 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount">
      </el-pagination>
      <el-table :data='tables' style='width: 100%' stripe :header-cell-style='setTableHeader'>
        <el-table-column prop='id' label='逻辑ID'> </el-table-column>
        <el-table-column prop='name' label='名称'>
          <template slot-scope='scope'>
            <el-button type='text' @click='handleUpdateLogic(scope.$index, scope.row)'>{{scope.row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop='debug' label='调试'>
          <template slot-scope='scope'>
            <el-switch v-model='scope.row.debug' active-color='#13ce66' inactive-color='#ff4949' disabled> </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop='timeout' label='HttpTimeout (ms)'> </el-table-column>
        <el-table-column label='操作' align='right'>
          <template slot-scope='scope'>
            <el-button type='info' size='mini' @click='handleDeleteLogic(scope.row.id, scope.$index)' icon='el-icon-delete' circle></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount">
      </el-pagination>
    </div>
    <!--逻辑模态框-->
    <el-dialog title='逻辑管理' :visible='dialogVisible' @close = handleDialogClose() label-position='left' top='4vh'>
      <el-form :model='form' label-position='right' label-width="100px" :rules='rules' ref='form'>
        <el-form-item label='逻辑ID' prop='id'>
          <el-input v-model.number='form.id' :disabled='type' ></el-input>
        </el-form-item>
        <el-form-item label='名称' prop='name'>
          <el-input v-model.trim='form.name'></el-input>
        </el-form-item>
        <el-form-item label='HttpTimeout' prop='timeout'>
          <el-input v-model.number='form.timeout' placeholder="Http Timeout (ms)"></el-input>
        </el-form-item>
        <el-form-item label='调试' prop='debug'>
          <el-switch v-model='form.debug' active-color='#13ce66' inactive-color='#ff4949'> </el-switch>
        </el-form-item>
        <el-form-item label='自定义参数'>
          <el-form size='mini' :model='form' >
            <el-form-item style="margin-top: 20px">
              <!--表头-->
              <el-form-item style='float: left;margin-bottom: 5px;width:100%'>
                <el-row :gutter='10' style='text-align: left;font-weight: bold'>
                  <el-col :span='7'><span>Key</span></el-col>
                  <el-col :span='7'><span>值</span></el-col>
                  <el-col :span='7'><span>名称</span></el-col>
                  <el-col :span='3'><span>操作</span></el-col>
                </el-row>
              </el-form-item>
              <!--参数-->
              <el-form-item v-for='(item, i) in form.args' :key='i' style='float: left;margin-bottom: 5px;width:100%'>
                <el-row :gutter='10'>
                  <el-col :span='7'><el-input v-model='item.key' placeholder='key' @change='handleSyncLabel(item)'> </el-input></el-col>
                  <el-col :span='7'><el-input v-model='item.value' placeholder='默认值'> </el-input></el-col>
                  <el-col :span='7'><el-input v-model='item.label' placeholder='名称'> </el-input></el-col>
                  <el-col :span='3'><el-button type='info' size='mini' circle icon="el-icon-delete" @click='handleDeleteParams(i)'> </el-button></el-col>
                </el-row>
              </el-form-item>
              <el-form-item style='float: left'>
                <el-button type='primary' size='mini' @click='handleAddParams'>添加</el-button>
              </el-form-item>
            </el-form-item>
          </el-form>
        </el-form-item>
      </el-form>
      <div slot='footer' class='dialog-footer' style='text-align: left'>
        <el-button type='primary' @click='handleSaveDialog()'>保 存</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import api from '@/api/channel'

export default {
  name: 'index',
  created () {
    this.fetchData(this.pagesize, this.currentPage)
  },
  computed: {
    tables () {
      const search = this.search
      if (search) {
        return this.tableData.filter(data => {
          return Object.keys(data).some(key => {
            return String(data[key]).toLowerCase().indexOf(search) > -1
          })
        })
      }
      return this.tableData
    }
  },
  data () {
    const timeout = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入时间'))
      } else if (typeof (value) !== 'number') {
        callback(new Error('请输入数字'))
      } else if (value > 60000 || value < 1000) {
        callback(new Error('时间限制为 1000ms - 60000ms'))
      } else {
        callback()
      }
    }
    return {
      pagesize: 20,
      currentPage: 1,
      totalCount: 0,
      tableData: [],
      platformData: {},
      dialogVisible: false,
      screenWidth: '',
      lgcName: '',
      search: '',
      form: {
        args: [{ label: '', key: '', value: '' }]
      },
      type: false, // 默认状态为新增  true 为修改 false为新增
      index: 0, // 用于修改时保存位置
      rules: {
        id: [{ type: 'number', required: true, message: '请输入数字，且不能为空', trigger: 'change' }],
        name: [{ type: 'string', required: true, message: '请输入名称', trigger: 'blur' }],
        timeout: [{ type: 'number', validator: timeout, trigger: 'blur' }],
        debug: [{ type: 'boolean', required: true, message: 'debug不能为空', trigger: 'change' }]
      }
    }
  },
  methods: {
    fetchData (n1, n2) {
      const params = {
        size: n1,
        page: n2,
        name: this.lgcName
      }
      api.getLogicDataByPage(params).then(res => {
        this.tableData = res.data
        this.totalCount = res.total
      })
    },
    handleSizeChange (val) {
      this.pagesize = val
      this.currentPage = 1
      this.fetchData(val, this.currentPage)
    },
    // 显示第几页
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchData(this.pagesize, val)
    },
    lgcNameChange (val) {
      this.lgcName = val
      this.currentPage = 1
      this.fetchData(this.pagesize, this.currentPage)
    },
    // 新增逻辑
    handleAddLogic () {
      this.type = false
      const id = this.tableData.length ? this.tableData[this.tableData.length - 1].id : 0
      this.form = {
        id: id + 1,
        debug: true,
        timeout: 3000,
        value: [{ label: '', key: '', value: '' }]
      }
      this.dialogVisible = true
    },
    // 修改逻辑
    handleUpdateLogic (index, rowData) {
      this.type = true
      this.index = index
      const data = Object.assign({}, rowData)
      data.args = JSON.parse(data.args)
      this.form = data
      this.dialogVisible = true
    },
    handleSyncLabel (item) {
      item.label = item.key
    },
    // 提交数据 新增 或 修改
    handleSaveDialog () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = Object.assign({}, this.form)
          if (params.args) {
            let len = params.args.length
            const arr = params.args
            while (len--) {
              if (arr[len].key === '') {
                arr.splice(len, 1)
              }
            }
          }
          params.args = params.args && params.args.length > 0 ? JSON.stringify(params.args) : null
          const f = this.type ? api.changeLogic : api.addLogic
          f(params).then(res => {
            if (this.type) {
              this.tableData.splice(this.index, 1, res)
            } else {
              this.tableData.push(res)
            }
            this.$message.success(this.type ? '修改成功' : '添加成功')
            this.handleDialogClose()
          })
        } else {
          return false
        }
      })
    },
    // 删除
    handleDeleteLogic (id, i) {
      this.$confirm('确定删除吗?', '提示', { type: 'warning' }).then(() => {
        api.deleteLogic(id).then(() => {
          this.tableData.splice(i, 1)
          this.$message.success('删除成功')
        })
      })
    },
    // 关闭弹窗
    handleDialogClose () {
      this.dialogVisible = false
      this.$refs.form.clearValidate()
    },
    // 添加参数
    handleAddParams () {
      const params = { label: '', key: '', value: '' }
      if (!this.form.args) {
        this.form.args = []
      }
      this.form.args.push(params)
    },
    // 删除参数
    handleDeleteParams (i) {
      this.form.args.splice(i, 1)
    },
    setTableHeader () {
      return 'color: #000;font-size:14px'
    }
  }
}
</script>

<style scoped>
  e_card {
    margin-bottom: 20px;
  }
</style>
