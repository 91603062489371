<template>
  <section>
    <div class="box">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex;">
          <el-button type="primary" @click="handleAddData">新增</el-button>
          <cache-reload reload-type="mchGroup" style="margin-left: 10px;"/>
        </div>
        <el-input v-model="search" placeholder="输入关键字搜索" style="width: 180px"></el-input>
      </div>
    </div>
    <div class="box">
      <!-- 分页器 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount">
      </el-pagination>
      <el-table :data="tables" stripe style="width: 100%" :header-cell-style="setTableHeader">
        <el-table-column prop="id" label="ID" width="120"> </el-table-column>
        <el-table-column prop="name" label="商户组名称">
          <template slot-scope="scope">
            <el-button type="text" @click="handleUpdateData(scope.$index, scope.row)">{{scope.row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="支付平台" :formatter="typeFormatter" width="120"> </el-table-column>
        <el-table-column prop="appId" label="默认商户AppId"> </el-table-column>
        <el-table-column prop="open" label="随机商户开关" width="160">
          <template slot-scope="scope">
            <el-switch :value="!!scope.row.open" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="data" label="随机商户数据">
          <template slot-scope="scope">
            <el-popover placement="bottom" trigger="click" :content="scope.row.data">
              <el-button slot="reference" size="mini" v-if="scope.row.data">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="right" width="60">
          <template slot-scope="scope">
            <el-button type="info" size="mini" circle @click="handleDelete(scope.row.id, scope.$index)" icon="el-icon-delete"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount">
      </el-pagination>
    </div>
    <!--    dialog-->
    <MchGroupDialog :visible.sync="dialogVisible" :formData="formData" :type="dialogType" :updateFun="() => fetchData(this.pageSize, this.currentPage)" />
  </section>
</template>

<script>
import api from '@/api/channel'
import MchGroupDialog from './GroupDialog.vue'
import CacheReload from '@/components/CacheReload/index.vue'

export default {
  name: 'MchGroup',
  components: { CacheReload, MchGroupDialog },
  created () {
    this.fetchData(this.pageSize, this.currentPage)
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    tables () {
      const search = this.search
      if (search) {
        return this.tableData.filter(data => {
          return Object.keys(data).some(key => {
            return String(data[key]).toLowerCase().indexOf(search) > -1
          })
        })
      }
      return this.tableData
    }
  },
  watch: {
    appId (val, oldVal) {
      if (val === oldVal) {
        return
      }
      this.fetchData(this.pageSize, this.currentPage)
    }
  },
  data () {
    return {
      pageSize: 20,
      currentPage: 1,
      totalCount: 0,
      tableData: [],
      channelData: {},
      search: '',
      formData: {},
      dialogVisible: false,
      /** add: 新增  edit：修改 */
      dialogType: 'add'
    }
  },
  methods: {
    fetchData (n1, n2) {
      const params = {
        size: n1,
        page: n2
      }
      api.getMchGroupDataByPage(params).then(res => {
        const list = res.data
        this.totalCount = res.total
        this.tableData = list
      })
    },
    // 表格分页
    handleSizeChange (val) {
      this.pageSize = val
      this.currentPage = 1
      this.fetchData(val, this.currentPage)
    },
    // 显示第几页
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchData(this.pageSize, val)
    },
    // 新增
    handleAddData () {
      this.dialogType = 'add'
      this.formData = {}
      this.dialogVisible = true
    },
    // 修改
    handleUpdateData (index, rowData) {
      console.log(rowData)
      this.dialogType = 'edit'
      const { data } = rowData
      this.formData = { ...rowData, data: JSON.parse(data) }
      this.dialogVisible = true
    },
    // 删除
    handleDelete (id, i) {
      this.$confirm('确定删除吗?', '提示', { type: 'warning' }).then(() => {
        api.deleteMchGroup(id).then(() => {
          this.tableData.splice(i, 1)
          this.$message.success('删除成功')
        })
      })
    },
    typeFormatter (row) {
      const type = row.type
      if (type === 1) {
        return type + '-' + '微信'
      } else if (type === 2) {
        return type + '-' + '支付宝'
      }
      return type
    },
    setTableHeader () {
      return 'color: #000'
    }
  }
}
</script>

<style scoped>

</style>
