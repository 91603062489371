<template>
  <section>
    <div style="margin-right: 15px; display: flex; justify-content: space-between;">
      <el-button type="success" size="mini" circle plain icon="el-icon-copy-document" @click="handleCopy()"></el-button>
      <el-button type="info" size="mini" circle plain icon="el-icon-view" @click="viewVisible = !viewVisible"></el-button>
    </div>
    <p v-if="viewVisible">{{ form[prop] }}</p>
    <!--      <el-popover placement="bottom" trigger="click" :content="JSON.stringify(value)">-->
    <!--        <el-button slot="reference" type="info" size="mini" circle plain icon="el-icon-view"></el-button>-->
    <!--      </el-popover>-->
    <el-form size="mini">
      <el-form-item style="margin-top: 5px">
        <!--表头-->
        <el-form-item style="float: left;margin-bottom: 5px;width:100%">
          <el-row :gutter="10" style="text-align: left;font-weight: bold">
            <el-col :span="7"><span>Key</span></el-col>
            <el-col :span="7"><span>值</span></el-col>
            <el-col :span="7"><span>名称</span></el-col>
            <el-col :span="3"><span>操作</span></el-col>
          </el-row>
        </el-form-item>
        <!--参数-->
        <el-form-item v-for="(item, i) in form[prop]" :key="i" style="float: left;margin-bottom: 5px;width:100%">
          <el-row :gutter="10">
            <el-col :span="7"><el-input v-model="item.key" placeholder="key" @change="handleSyncLabel(item)"> </el-input></el-col>
            <el-col :span="7"><el-input v-model="item.value" placeholder="默认值"> </el-input></el-col>
            <el-col :span="7"><el-input v-model="item.label" placeholder="名称"> </el-input></el-col>
            <el-col :span="3"><el-button type="danger" size="mini" circle icon="el-icon-minus" @click="handleDeleteParams(i)"> </el-button></el-col>
          </el-row>
        </el-form-item>
        <el-form-item style="float: left">
          <el-button type="primary" size="mini" icon="el-icon-plus" @click="handleAddParams()"></el-button>
        </el-form-item>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
export default {
  name: 'ArgsKvl',
  props: {
    form: Object,
    prop: {
      type: String,
      default: 'args'
    }
  },
  data () {
    return {
      viewVisible: false
    }
  },
  methods: {
    handleCopy () {
      navigator.clipboard.writeText(JSON.stringify(this.form[this.prop])).then(() => {
        this.$message.success('复制成功')
      })
    },
    // 添加参数
    handleAddParams () {
      const params = { label: '', key: '', value: '' }
      this.form[this.prop].push(params)
    },
    // 删除参数
    handleDeleteParams (i) {
      this.form[this.prop].splice(i, 1)
    },
    handleSyncLabel (item) {
      if (!item.label) {
        item.label = item.key
      }
    }
  }
}
</script>

<style scoped>

</style>
