<template>
  <section>
    <!-- 重载Cache -->
    <div class="box" style="display: flex; justify-content: space-between; align-items: center;">
      <cache-reload reload-type="xsArgsLogin" btn-label="重载LoginArgs" btn-icon="el-icon-user"/>
      <cache-reload reload-type="xsArgsPay" btn-label="重载PayArgs" btn-icon="el-icon-shopping-bag-2"/>
    </div>
    <!-- 登录参数 -->
    <logic-args :owner-type="1" :args-id="appId" :logic-type="1"></logic-args>
    <!-- 支付参数 -->
    <logic-args :owner-type="1" :args-id="appId" :logic-type="2"></logic-args>
  </section>
</template>

<script>
import LogicArgs from '@/views/Xs/Args/LogicArgs.vue'
import CacheReload from '@/components/CacheReload/index.vue'

export default {
  name: 'XsArgsGame',
  components: { CacheReload, LogicArgs },

  data () {
    return {
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
