<template>
  <section>
    <el-button :type="btnType" plain :icon="btnIcon" @click="handleCacheReload()">{{btnLabel}}</el-button>
  </section>
</template>

<script>
import api from '@/api/channel'

export default {
  name: 'CacheReload',
  props: {
    form: Object,
    btnType: {
      type: String,
      default: 'warning'
    },
    btnLabel: {
      type: String,
      default: '重载'
    },
    btnIcon: {
      type: String,
      default: 'el-icon-refresh'
    },
    reloadType: {
      type: String,
      required: true
    }
  },
  data () {
    const reloadTypeUrls = {
      game: '/channel/admin/cache/reload/game',
      channel: '/channel/admin/cache/reload/channel',
      xsArgsLogin: '/channel/admin/cache/reload/xsArgs/login',
      xsArgsPay: '/channel/admin/cache/reload/xsArgs/pay',
      mchGroup: '/channel/admin/cache/reload/mch/group',
      mchWx: '/channel/admin/cache/reload/mch/wx',
      mchAli: '/channel/admin/cache/reload/mch/ali',
      mchApple: '/channel/admin/cache/reload/mch/apple',
      extGameConfig: '/channel/admin/cache/reload/ext/config/game',
      extChannelConfig: '/channel/admin/cache/reload/ext/config/channel'
    }
    return {
      reloadTypeUrls: reloadTypeUrls
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    }
  },
  methods: {
    getReloadUrl () {
      return this.reloadTypeUrls[this.reloadType]
    },
    handleCacheReload () {
      const url = this.getReloadUrl()
      if (!url) {
        this.$message.error('[err]组件错误: reloadType 不存在')
        return
      }
      this.$confirm('你确定要立即重载么?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = { appId: this.appId }
        api.cacheReload(url, params).then(res => {
          this.$message.success('重载成功')
        })
      }).catch(() => {
        this.$message.info('已取消重载')
      })
    }
  }
}
</script>

<style scoped>

</style>
