import { render, staticRenderFns } from "./Table.vue?vue&type=template&id=042238ce&scoped=true&functional=true"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "042238ce",
  null
  
)

export default component.exports