<template>
  <rest
    :remote="remote"
    :table-columns="tableColumns"
    :formInitialize="formInitialize"
    :form-rules="formRules"
    :exportable="true"
    :create-able="true" :update-able="true" :delete-able="true"
  >
  </rest>
</template>

<script>

import Rest from '@/components/Spring/Rest'

export default {
  name: 'mchApples',
  components: { Rest },

  data () {
    return {
      remote: {
        path: 'channel',
        resource: this.$options.name,
        realtime: false,
        reloadUrl: '/channel/admin/cache/reload/mch/apple'
      },
      formInitialize: {
        products: []
      },
      formRules: {
        name: [{ required: true, message: '请填写名称' }],
        appId: [{ required: true, message: '请填写应用ID' }],
        bundleId: [{ required: true, message: '请填写BundleID' }],
        issuerId: [{ required: true, message: '请填写IssuerID' }],
        kid: [{ required: true, message: '请填写KID' }],
        appPrivateKey: [{ required: true, message: '请填写PrivateKey' }]
      }
    }
  },
  computed: {
    tableColumns () {
      return [
        { label: 'ID', prop: 'id', hiddenForm: true },
        { label: '名称', prop: 'name' },
        { label: '应用ID', prop: 'appId' },
        { label: 'BundleID', prop: 'bundleId' },
        { label: 'IssuerID', prop: 'issuerId' },
        { label: 'KID', prop: 'kid' },
        { label: 'PrivateKey', prop: 'appPrivateKey', type: 'textarea' },
        { label: 'Products', prop: 'products', type: 'productArgs', codec: 'json' }
        // { label: 'Products', prop: 'products', type: 'productTemplate', codec: 'json' }
      ]
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style scoped>
</style>
