<template>
  <section>
    <div style="margin-right: 15px; display: flex; justify-content: space-between;">
      <el-button type="success" size="mini" circle plain icon="el-icon-copy-document" @click="handleCopy()"></el-button>
      <el-button type="info" size="mini" circle plain icon="el-icon-view" @click="viewVisible = !viewVisible"></el-button>
    </div>
    <p v-if="viewVisible">{{ form[prop] }}</p>
    <el-form size="mini">
      <el-form-item style="margin-top: 5px">
        <!--表头-->
        <el-form-item style="float: left;margin-bottom: 5px;width:100%">
          <el-row :gutter="10" style="text-align: left;font-weight: bold">
            <el-col :span="5"><span>商品ID</span></el-col>
            <el-col :span="5"><span>价格(分)</span></el-col>
            <el-col :span="6"><span>商品名称</span></el-col>
            <el-col :span="6"><span>商品描述</span></el-col>
            <el-col :span="2"><span>操作</span></el-col>
          </el-row>
        </el-form-item>
        <!--参数-->
        <el-form-item v-for="(item, i) in form[prop]" :key="i" style="float: left;margin-bottom: 5px;width:100%">
          <el-row :gutter="10">
            <el-col :span="5"><el-input v-model="item.id" placeholder="商品ID"> </el-input></el-col>
            <el-col :span="5"><el-input v-model="item.price" placeholder="价格(分)"> </el-input></el-col>
            <el-col :span="6"><el-input v-model="item.name" placeholder="商品名称"> </el-input></el-col>
            <el-col :span="6"><el-input v-model="item.desc" placeholder="商品描述"> </el-input></el-col>
            <el-col :span="2"><el-button type="danger" size="mini" circle icon="el-icon-minus" @click="handleDeleteParams(i)"> </el-button></el-col>
          </el-row>
        </el-form-item>
        <el-form-item style="float: left">
          <el-button type="primary" size="mini" icon="el-icon-plus" @click="handleAddParams()"></el-button>
        </el-form-item>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
export default {
  name: 'ProductTemplate',
  props: {
    form: Object,
    prop: {
      type: String,
      default: 'products'
    }
  },
  data () {
    return {
      viewVisible: false
    }
  },
  methods: {
    handleCopy () {
      navigator.clipboard.writeText(JSON.stringify(this.form[this.prop])).then(() => {
        this.$message.success('复制成功')
      })
    },
    // 添加参数
    handleAddParams () {
      const params = { id: '', name: '', price: 0, desc: '' }
      this.form[this.prop].push(params)
    },
    // 删除参数
    handleDeleteParams (i) {
      this.form[this.prop].splice(i, 1)
    }
  }
}
</script>

<style scoped>

</style>
