<template>
  <rest
    :remote="remote"
    :table-columns="tableColumns"
    :formInitialize="formInitialize"
    :form-rules="formRules"
    :exportable="true"
    :create-able="true" :update-able="true" :delete-able="true"
  >
  </rest>
</template>

<script>

import Rest from '@/components/Spring/Rest'

export default {
  name: 'mchAlis',
  components: { Rest },

  data () {
    return {
      remote: {
        path: 'channel',
        resource: this.$options.name,
        realtime: false,
        reloadUrl: '/channel/admin/cache/reload/mch/ali'
      },
      formInitialize: {},
      formRules: {
        name: [{ required: true, message: '请填写名称' }],
        appId: [{ required: true, message: '请填写应用ID' }],
        mchId: [{ required: true, message: '请填写商户ID' }],
        mchName: [{ required: true, message: '请填写商户名称' }],
        appPrivateKey: [{ required: true, message: '请填写应用私钥' }],
        appPublicKey: [{ required: true, message: '请填写应用公钥' }],
        aliPublicKey: [{ required: true, message: '请填写支付宝公钥' }],
        notifyUrl: [{ type: 'url', required: true, message: 'NotifyUrl格式不正确' }]
      }
    }
  },
  computed: {
    tableColumns () {
      return [
        { label: 'ID', prop: 'id', hiddenForm: true },
        { label: '名称', prop: 'name' },
        { label: '应用ID', prop: 'appId' },
        { label: '商户ID', prop: 'mchId' },
        { label: '商户名称', prop: 'mchName' },
        { label: '应用私钥', prop: 'appPrivateKey', type: 'textarea' },
        { label: '应用公钥', prop: 'appPublicKey', type: 'textarea' },
        { label: '支付宝公钥', prop: 'aliPublicKey', type: 'textarea' },
        { label: 'NotifyUrl', prop: 'notifyUrl', placeholder: 'NotifyUrl 支付回调通知地址' }
      ]
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style scoped>
</style>
