<template>
  <rest
    :remote="remote"
    :table-columns="tableColumns"
    :formInitialize="formInitialize"
    :form-rules="formRules"
    :exportable="true"
    :create-able="true" :update-able="true" :delete-able="true"
  >
  </rest>
</template>

<script>
import Rest from '../../../components/Spring/Rest.vue'
export default {
  name: 'xsLogics',
  components: { Rest },

  data () {
    return {
      remote: {
        path: 'channel',
        resource: this.$options.name,
        realtime: true
      },
      formInitialize: {
        args: [],
        timeout: 3000
      },
      formRules: {
        type: [{ required: true, message: '请填写 Type' }],
        logicId: [{ required: true, message: '请填写 logicId' }],
        name: [{ required: true, message: '请填写 name' }],
        args: [{ required: false, message: '请填写 args' }]
        // debug: [{ required: false, message: '请填写 debug' }],
        // timeout: [{ required: true, message: '请填写 Timeout' }]
      }
    }
  },
  computed: {
    tableColumns () {
      return [
        // { label: 'ID', prop: 'id', hiddenForm: true },
        {
          label: 'Type',
          prop: 'type',
          type: 'select',
          options: [
            { value: 1, label: '登录' },
            { value: 2, label: '支付' }
          ]
        },
        { label: 'LogicId', prop: 'logicId' },
        { label: 'Name', prop: 'name' },
        { label: 'Args', prop: 'args', type: 'argsKvl', codec: 'json' }
        // { label: 'Debug', prop: 'debug', type: 'switch' },
        // { label: 'Timeout(ms)', prop: 'timeout', type: 'number', min: 1000, max: 10000 }
      ]
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
