<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html, body {
  margin: 0;
  padding: 0;
}
html,body,#app{
  height: 100%;
  background: #ECF0F5; /*白色*/
  /*background: #33384B; !*黑色*!*/
}
.box {
  margin-top: 10px;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #EBEEF5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
.el-form--inline .el-form-item {
  margin-bottom: 5px;
}
</style>
