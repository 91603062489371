<template>
  <!--逻辑模态框-->
  <el-dialog title='渠道' :visible='dialogVisible' @close='handleCloseDialog' label-position='left' top='4vh'>
    <el-form :model='form' label-position='right' label-width="120px" :rules='rules' ref='form'>
      <el-form-item label='平台ID' prop='platformId'>
        <el-select v-model='form.platformId' filterable placeholder='请选择' style='width: 100%'
                   @change='handleChangePlatformId(form.platformId)' clearable>
          <el-option v-for='item in platformData' :key='item.platformId'
                     :label='item.platformId + "- " + item.name + "----[" + item.min + "," + item.max + ")"'
                     :value='item.platformId'>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label='渠道ID' prop='id'>
        <el-input v-model.number='form.id' :disabled='type' style='width: 100%' controls-position='right' :min='0'></el-input>
      </el-form-item>
      <el-form-item label='账号渠道ID' prop='accountChannelId'>
        <el-input v-model.number='form.accountChannelId' :disabled='accountChannelType' style='width: 100%'
                  controls-position='right' :min='0'></el-input>
      </el-form-item>
      <el-form-item label="ArgsGroupId" prop="argsGroupId">
        <el-input v-model.number="form.argsGroupId" style="width: 100%" controls-position="right" :min="0"></el-input>
      </el-form-item>
      <el-form-item label="XsType" prop="xsType">
        <el-select v-model.number="form.xsType" placeholder="XsType" clearable>
          <el-option v-for="item in xsTypeOption" :label="item.value + '-' + item.label" :value="item.value" :key="item.value"></el-option>
        </el-select>
      </el-form-item>
<!--      <el-form-item label="XsLoginArgsId" prop="xsLoginArgsId">-->
<!--        <el-input v-model="form.xsLoginArgsId" placeholder="XsLoginArgsId"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label='XsPayArgsId' prop='xsPayArgsId'>-->
<!--        <el-input v-model="form.xsPayArgsId" placeholder="XsPayArgsId"></el-input>-->
<!--      </el-form-item>-->
      <el-form-item label='渠道名称' prop='name'>
        <el-input v-model.trim='form.name'></el-input>
      </el-form-item>
      <el-form-item label='状态' prop='status'>
        <el-select v-model='form.status' filterable placeholder='请选择' style='width: 100%'
                   @change='handleChangeStatus(form.status)' clearable>
          <el-option v-for='item in statusOption' :key='item.value' :label='item.value + " - " + item.label'
                     :value='item.value'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label='包类型' prop='packageType'>
        <el-select v-model.trim='form.packageType' filterable placeholder='请选择' style='width: 100%' @change="handlePackageTypeChange">
          <el-option value="0">0 - Unknown</el-option>
          <el-option v-for='item in packageTypes' :key='item.value' :label='item.value + " - " + item.label' :value='item.value'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label='渠道类型' prop='channelType'>
        <el-select v-model.trim='form.channelType' filterable placeholder='请选择' style='width: 100%' :loading="loading">
          <el-option value="0">0 - Unknown</el-option>
          <el-option v-for='item in channelTypeOptions' :key='item.id' :label='item.id + " - " + item.name' :value='item.id'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label='os(弃)' prop='os'>
        <el-select v-model.trim='form.os' filterable placeholder='请选择' style='width: 100%'>
          <el-option v-for='item in os' :key='item.value' :label='item.label' :value='item.value'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="CP-NotifyUrl" prop="notifyUrl">
        <el-input v-model.trim="form.notifyUrl" placeholder="自定义 NotifyUrl"></el-input>
      </el-form-item>
      <el-form-item label="CP-H5Url" prop="h5LoginUrl">
        <el-input v-model.trim="form.h5LoginUrl" placeholder="自定义 H5LoginUrl"></el-input>
      </el-form-item>
      <el-form-item label="CP-微端Url" prop="gameUrl">
        <el-input v-model.trim="form.gameUrl" placeholder="自定义 微端GameUrl"></el-input>
      </el-form-item>
      <el-form-item label="网关域名" prop="areaId">
        <el-select v-model.number="form.areaId" filterable placeholder="自定义 网关域名" style="width: 100%;" @change="handleChangeAreaArgs(form.areaId)" clearable>
          <el-option v-for="area in areaData" :key="area.id" :label="area.id + '-' + area.name" :value="area.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Pluto" prop="plutoOpen">
        <el-switch v-model="form.plutoOpen" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item label="UFW" prop="ufwOpen">
        <el-switch v-model="form.ufwOpen" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item label="实名认证" prop="realAuthOpen">
        <el-switch v-model="form.realAuthOpen" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item label="手机号绑定" prop="phoneBindOpen">
        <el-switch v-model="form.phoneBindOpen" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item label="预约归因" prop="reserveAttributeOpen">
        <el-switch v-model="form.reserveAttributeOpen" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item label="防沉迷" prop="juvenileOpen">
        <el-switch v-model="form.juvenileOpen" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item label="PC-扫码登录" prop="pcQrcodeLogin">
        <el-select v-model="form.pcQrcodeLogin" multiple placeholder="请选择" style='width: 100%'>
          <el-option label="1-游戏APP扫码" :value="1"></el-option>
          <el-option label="2-微信小程序扫码" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="PC-生成码地址" prop="pcWxQrcodeUrl">
        <el-input v-model="form.pcWxQrcodeUrl" placeholder='请输入生成微信小程序码地址'></el-input>
      </el-form-item>
      <el-form-item label="PC-支付方式" prop="pcPayType">
        <el-select v-model="form.pcPayType" placeholder="请选择" style='width: 100%'>
          <el-option label="1-游戏内支付" :value="1"></el-option>
          <el-option label="2-跳转官网支付" :value="2"></el-option>
          <el-option label="3-游戏APP充值" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="PC-支付内容" prop="pcPayText">
        <el-input v-model="form.pcPayText" placeholder='请输入PC端展示的支付内容（URL、文本）'></el-input>
      </el-form-item>
      <el-form-item label="PC-客服地址" prop="pcKeFuUrl">
        <el-input v-model="form.pcKeFuUrl" placeholder='请输入PC端客服地址'></el-input>
      </el-form-item>
      <el-form-item label="适龄" prop="minAge">
        <el-input-number v-model="form.minAge" :min="0" :max="100"></el-input-number>
      </el-form-item>
      <el-form-item label="备案识别码" prop="bizId">
        <el-input v-model="form.bizId" placeholder='请输入备案识别码 bizId'></el-input>
      </el-form-item>
      <el-form-item label="折扣率" prop="rate">
        <el-select v-model.trim='form.rate' filterable placeholder='请选择' style='width: 100%'>
          <el-option v-for='item in rateList' :key='item.value' :label='item.label' :value='item.value'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="包名" prop="packageName">
        <el-input v-model="form.packageName" placeholder="请输入包名"></el-input>
      </el-form-item>
      <!-- TODO: 注释渠道限制 -->
      <!-- <el-form-item label="渠道注册限制" prop="regLimitOpen">
        <el-switch v-model="form.regLimitOpen" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item label="限制用户数" prop="limitRegNum" v-show="form.regLimitOpen">
        <el-input-number v-model="form.limitRegNum" :min="0" :max="100000"></el-input-number>
      </el-form-item>
      <el-form-item label="限制时间段" prop="limitRegTime" v-show="form.regLimitOpen">
        <el-date-picker
          v-model="form.limitRegStartTime"
          prop="limitRegStartTime"
          type="datetime"
          placeholder="开始日期"
          :picker-options="startTimePicker">
        </el-date-picker>
        <el-date-picker
          v-model="form.limitRegEndTime"
          prop="limitRegEndTime"
          type="datetime"
          placeholder="结束日期"
          :picker-options="endTimePicker">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="限制提示语" prop="limitRegTip" v-show="form.regLimitOpen">
        <el-input v-model="form.limitRegTip" placeholder='请输入渠道注册限制提示语'></el-input>
      </el-form-item> -->
      <el-form-item label='切换用户' prop='shadowType'>
        <el-select v-model.trim='form.shadowType' filterable placeholder='请选择' style='width: 100%'>
          <el-option v-for='item in shadowTypes' :key='item.value' :label='item.label' :value='item.value'>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot='footer' class='dialog-footer' style='text-align: left'>
      <el-button type='primary' @click='handleSaveDialog'>保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import options from '@/common/options'
import api from '@/api/channel'

export default {
  name: 'Logic',
  props: {
    dialogVisible: Boolean,
    channelData: Object
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    channelTypeList () {
      return this.$store.getters.channelTypeList
    }
  },
  watch: {
    channelData: function (val) {
      this.fetchData()
      this.type = !!val.name
      this.form = Object.assign(this.form, val)
      this.form.pcQrcodeLogin = JSON.parse(val.pcQrcodeLogin || '[]')
      this.channelTypeOptions = this.channelTypeList.filter((item) => item.packageType === this.form.packageType) || []
      if (this.form.status === 5) {
        this.accountChannelType = true
      }
    },
    'form.id' (val, oldVal) {
      if (val === oldVal || this.type) {
        return
      }
      this.form.accountChannelId = val
    },
    'form.accountChannelId' (val, oldVal) {
      if (val === oldVal || this.type) {
        return
      }
      this.form.argsGroupId = val
    }
  },
  data () {
    const idRules = (rule, value, callback) => {
      // 不选择平台的话限制为当前游戏ID * 1000，选择平台的话按照平台大小限制
      let min = this.appId * 1000
      let max = (this.appId + 1) * 1000
      if (this.platformData.length) {
        for (const item of this.platformData) {
          if (item.platformId === this.form.platformId) {
            min = item.min
            max = item.max
          }
        }
      }
      if (value === '') {
        callback(new Error('此字段必填'))
      } else if (typeof (value) !== 'number') {
        callback(new Error('请输入数字'))
      } else if (value < 0) {
        callback(new Error('请输入有效数字'))
      } else if (value < min) {
        callback(new Error('应大于等于' + min))
      } else if (value >= max) {
        callback(new Error('应小于' + max))
      } else {
        callback()
      }
    }
    const xsArgsIdRules = (rule, value, callback) => {
      // 允许不填
      if (!value || this.platformData.length) {
        callback()
      }
      // 不选择平台的话限制为当前游戏ID * 1000，选择平台的话按照平台大小限制
      let min = this.appId * 1000
      let max = (this.appId + 1) * 1000
      if (this.platformData) {
        for (const item of this.platformData) {
          if (item.platformId === this.form.platformId) {
            min = item.min
            max = item.max
          }
        }
      }
      if (typeof (value) !== 'number') {
        callback(new Error('请输入数字'))
      } else if (value < min) {
        callback(new Error('应大于等于' + min))
      } else if (value >= max) {
        callback(new Error('应小于' + max))
      } else {
        callback()
      }
    }
    return {
      form: {
        id: 0,
        name: '',
        status: 0,
        accountChannelId: 0,
        argsGroupId: 0,
        shadowType: 0,
        packageType: 0,
        channelType: 0,
        os: '',
        notifyUrl: '',
        h5LoginUrl: '',
        gameUrl: '',
        realAuthOpen: false,
        phoneBindOpen: false,
        reserveAttributeOpen: false,
        juvenileOpen: false,
        pcQrcodeLogin: '',
        pcWxQrcodeUrl: '',
        pcPayType: '',
        pcPayText: '',
        pcKeFuUrl: '',
        plutoOpen: false,
        ufwOpen: false,
        minAge: 0,
        bizId: '',
        packageName: '',
        rate: 0,
        areaId: 0,
        // regLimitOpen: false,
        // limitRegNum: 0,
        // limitRegStartTime: '',
        // limitRegEndTime: '',
        // limitRegTip: '',
        xsType: 0,
        xsLoginArgsId: '',
        xsPayArgsId: ''
      },
      loading: false,
      type: false, // 默认状态为新增  true 为修改 false为新增
      accountChannelType: false,
      limitRegTime: false,
      // appOptions: [],
      os: options.os,
      packageTypes: options.packageTypes,
      rateList: options.rateOption,
      channelTypeOptions: [],
      shadowTypes: options.shadowTypes,
      platformData: [],
      areaData: [],
      statusOption: options.statusOption,
      xsTypeOption: options.xsTypeOption,
      rules: {
        id: [{ type: 'number', required: true, validator: idRules, trigger: 'change' }],
        name: [{ type: 'string', required: true, message: '请输入名称', trigger: 'blur' }],
        platformId: [{ type: 'number', required: true, message: 'platformId', trigger: 'change' }],
        accountChannelId: [{ type: 'number', required: true, validator: idRules, trigger: 'blur' }],
        argsGroupId: [{ type: 'number', required: true, validator: idRules, trigger: 'blur' }],
        shadowType: [{ type: 'number', required: true, trigger: 'change' }],
        notifyUrl: [{ type: 'url', required: false, message: 'NotifyUrl格式不正确', trigger: 'blur' }],
        h5LoginUrl: [{ type: 'url', required: false, message: 'h5LoginUrl格式不正确', trigger: 'blur' }],
        gameUrl: [{ type: 'url', required: false, message: 'gameUrl格式不正确', trigger: 'blur' }],
        areaId: [{ type: 'number', required: false, message: '请选择环境', trigger: 'blur' }],
        status: [{ type: 'number', required: true, message: '请选择对接状态', trigger: 'blur' }],
        // limitRegStartTime: [{ type: 'datetime', required: true, validator: limitRegStartTime, trigger: 'change' }],
        // limitRegEndTime: [{ type: 'datetime', required: true, validator: limitRegEndTime, trigger: 'change' }],
        xsType: [{ type: 'number', required: true, trigger: 'blur' }],
        xsLoginArgsId: [{ required: false, validator: xsArgsIdRules, trigger: 'blur' }],
        xsPayArgsId: [{ required: false, validator: xsArgsIdRules, trigger: 'blur' }]
      }
    }
  },
  methods: {
    fetchData () {
      api.getPlatformData(this.appId).then(res => {
        this.platformData = res
      })
      api.getAreas().then(res => {
        this.areaData = [{ id: 0, name: '非自定义' }, ...res]
      })
    },
    handlePackageTypeChange (val) {
      const item = this.packageTypes.find(item => item.value === val)
      if (item) {
        this.form.os = item.os
      }
      this.channelTypeOptions = this.channelTypeList.filter((item) => item.packageType === val) || []
    },
    // 关闭模态框
    handleCloseDialog () {
      this.$refs.form.clearValidate()
      this.$emit('handleCloseDialog', false)
    },
    // 当参数模板的环境ID改变时
    handleChangeAreaArgs (areaId) {
      // todo: del
      console.log('--- areaId', areaId)
      // for (const item of this.areaData) {
      //   if (item.id === areaId) {
      //     this.form.gatewayUrl = item.callbackUrl
      //   }
      // }
    },
    handleChangeStatus (status) {
      this.$forceUpdate()
      for (const item of this.statusOption) {
        if (item.value === status) {
          this.form.status = item.value
        }
      }
    },
    handleChangePlatformId (platformId) {
      this.$forceUpdate()
      for (const item of this.platformData) {
        if (item.platformId === platformId) {
          this.form.platformId = item.platformId
        }
      }
    },
    // 保存数据并关闭模态框
    handleSaveDialog () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = Object.assign({}, this.form)
          params.appId = this.appId
          params.pcQrcodeLogin = JSON.stringify(params.pcQrcodeLogin)
          const f = this.type ? api.changeChannel : api.addChannel
          f(params).then(() => {
            this.$message.success(this.type ? '修改成功' : '添加成功')
            this.$emit('handleSaveDialog', false)
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
