import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/components/Layout'

Vue.use(Router)

// 在当前页面时候再次点击当前页面，会有警告！
// 原因：在路由中添加了相同的路由。
// 解决： 重写路由的push方法
const routerPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}

function load (view) {
  // return () => import(`@/views/${view}.vue`)
  return resolve => require([`@/views/${view}.vue`], resolve)
}

export default new Router({
  routes: [
    { path: '/login', component: load('Login'), hidden: true },
    {
      path: '/',
      component: Layout,
      hidden: true,
      redirect: '/home',
      children: [{ path: '/home', name: '首页', component: load('index'), icon: 'home' }]
    },
    {
      path: '/p',
      component: Layout,
      name: '',
      icon: 'el-icon-message',
      children: [{ path: '/p', name: '产品管理', component: load('Product/index'), icon: 'fab fa-product-hunt' }]
    },
    {
      path: '/pl',
      component: Layout,
      children: [{ path: '/pl', name: '平台管理', component: load('Platform/index'), icon: 'fa-sliders' }]
    },
    {
      path: '/l',
      component: Layout,
      children: [{ path: '/l', name: '逻辑管理', component: load('Logic/index'), icon: 'fa-microchip' }]
    },
    {
      path: '/c',
      component: Layout,
      children: [{ path: '/c/cl', name: '渠道列表', component: load('Channel/index'), icon: 'fa-c' }]
    },
    {
      path: '/n',
      component: Layout,
      name: '',
      icon: 'el-icon-message',
      children: [{ path: '/n', name: '同步参数', component: load('Node/index'), icon: 'fa-rotate' }]
    },
    {
      path: '/ct',
      component: Layout,
      children: [{ path: '/ct', name: 'ChannelType', component: load('ChannelType/index'), icon: 'fa-tenge' }]
    },
    {
      path: '/pkg',
      component: Layout,
      name: '',
      icon: 'el-icon-message',
      children: [{ path: '/pkg', name: '证书配置', component: load('Pkg/index'), icon: 'fa-certificate' }]
    },
    {
      path: '/advert',
      component: Layout,
      name: '广告变现',
      subMenu: true,
      icon: 'fab fa-adversal',
      children: [
        { path: '/advert/app', name: '广告应用', component: load('Advert/App'), icon: 'fab fa-adversal' },
        { path: '/advert/channel', name: '广告渠道', component: load('Advert/Channel'), icon: 'fa-yen-sign' }
      ]
    },
    {
      path: '/config',
      component: Layout,
      name: '环境设置',
      subMenu: true,
      icon: 'fa-gear',
      children: [
        { path: '/ufo/group', name: 'UFO分组', component: load('UfoGroup/index'), icon: 'fa-layer-group' },
        { path: '/area', name: '网关域名', component: load('Area/index'), icon: 'fa-object-group' },
        { path: '/holiday', name: '节假日管理', component: load('Holiday/index'), icon: 'fa fa-calendar' }
      ]
    },
    {
      path: '/ufw',
      component: Layout,
      name: 'ufw管理',
      subMenu: true,
      icon: 'fa-shield-heart',
      children: [
        { path: '/ufw', name: '节点内存', component: load('Ufw/index'), icon: 'fa-memory' },
        { path: '/ufw/black', name: 'ufw黑名单', component: load('Ufw/blackUfw'), icon: 'fa-solid fa-passport' },
        { path: '/ufw/white', name: 'ufw白名单', component: load('Ufw/whiteUfw'), icon: 'fa-passport' }
      ]
    },
    {
      path: '/company',
      component: Layout,
      name: '公司主体',
      subMenu: true,
      icon: 'fa-building-columns',
      children: [
        { path: '/company', name: '公司管理', component: load('Company/Company'), icon: 'fa-building' },
        { path: '/organize', name: '组织管理', component: load('Company/Organize'), icon: 'fa-building-columns' }
      ]
    },
    {
      path: '/mch',
      component: Layout,
      name: '商户管理',
      subMenu: true,
      icon: 'fa-m',
      children: [
        { path: '/mch/group', name: '商户组管理', component: load('Mch/Group'), icon: 'fa-layer-group' },
        { path: '/mch/wx', name: '微信商户', component: load('Mch/Wx/index'), icon: 'fa-comments' },
        { path: '/mch/ali', name: '支付宝商户', component: load('Mch/Ali/index'), icon: 'fab fa-alipay' },
        { path: '/mch/apple', name: 'Apple应用', component: load('Mch/Apple/index'), icon: 'fa-apple-whole' }
      ]
    },
    {
      path: '/xs',
      component: Layout,
      name: '帐号支付',
      subMenu: true,
      icon: 'fa-x',
      children: [
        { path: '/xs/args/game', name: 'XS游戏', component: load('Xs/Args/Game'), icon: 'fa-g' },
        { path: '/xs/args/channel', name: 'XS渠道', component: load('Xs/Args/Channel'), icon: 'fa-c' },
        { path: '/xs/args/logic', name: 'XS逻辑', component: load('Xs/Logic/index'), icon: 'fa-l' },
        { path: '/xs/telecom/auth', name: '电信认证', component: load('Xs/TelecomAuth/index'), icon: 'fa-t' },
        // { path: '/xs/args/login', name: '登录Args', component: load('Xs/ArgsLogin/index'), icon: 'fa-a' },
        { path: '/xs/args/pay', name: '支付Args', component: load('Xs/ArgsPay/index'), icon: 'fa-a' }
      ]
    },
    {
      path: '/ext',
      component: Layout,
      name: '扩展配置',
      subMenu: true,
      icon: 'fa-e',
      children: [
        { path: '/ext/config/game', name: 'Ext游戏', component: load('Ext/GameConfig'), icon: 'fa-g' },
        { path: '/ext/config/channel', name: 'Ext渠道', component: load('Ext/ChannelConfig'), icon: 'fa-c' },
        { path: '/ext/config/template', name: 'Ext模版', component: load('Ext/ConfigTemplate'), icon: 'fa-t' }
      ]
    },
    {
      path: '/short',
      component: Layout,
      name: '短链管理',
      subMenu: true,
      icon: 'fa-link',
      children: [
        { path: '/short/url', name: '短链映射', component: load('ShortUrl/UrlMap'), icon: 'fa-link-slash' },
        { path: '/short/prefix', name: '前缀域名', component: load('ShortUrl/Prefix'), icon: 'fa-hashtag' }
      ]
    },
    {
      path: '/wiki',
      component: Layout,
      name: 'WIKI INFO',
      subMenu: true,
      icon: 'fab fa-wikipedia-w',
      children: [
        { path: '/wiki/app', name: '应用分配', component: load('Wiki/WikiApp'), icon: 'fa-cube' },
        { path: '/wiki/vpc', name: 'VPC规划', component: load('Wiki/WikiVpc'), icon: 'fa-network-wired' }
      ]
    },
    // Always leave this last one
    { path: '*', component: load('404'), hidden: true }
  ]
})
